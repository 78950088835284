import { IconButton } from "@mui/material";
import AppButton from "../../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import {
  AppForm,
  AppFormDatePicker,
  AppFormField,
  AppFormSelectField,
  AppFormTextArea,
  AppFormTimePickerMui,
} from "../../../../../AppComponents/Forms";
import { ProtectedAppUiElement } from "../../../../../AppComponents/ProtectedComponents";
import AppModel from "../../../../../Hooks/useModel/AppModel";
import useModal from "../../../../../Hooks/useModel/useModel";
import { ReactComponent as ChromeIcon } from "../../../../../assets/images/chrome-icon.svg";
import { ReactComponent as MailIcon } from "../../../../../assets/images/coloremail-icon.svg";
import { ReactComponent as PhoneIcon } from "../../../../../assets/images/colorphone-icon.svg";
import { ReactComponent as MsgIcon } from "../../../../../assets/images/colorwhatsapp-icon.svg";
import { ReactComponent as CopyIcon } from "../../../../../assets/images/copy-icon.svg";
import Danger from "../../../../../assets/images/danger-live.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/delete-icon.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/images/edit-icon.svg";
import { ReactComponent as FacebookIcon } from "../../../../../assets/images/facebook-icon.svg";
import { ReactComponent as LinkdinIcon } from "../../../../../assets/images/linkdin-icon.svg";
import { ReactComponent as LocationIcon } from "../../../../../assets/images/location-icon.svg";
import { ReactComponent as OpenIcon } from "../../../../../assets/images/open-icon.svg";
import { ReactComponent as ProfileIcon } from "../../../../../assets/images/profile-icon.svg";
import { ReactComponent as SaveIcon } from "../../../../../assets/images/save-icon.svg";
import { ReactComponent as ScreenIcon } from "../../../../../assets/images/screen-icon.svg";
import { ReactComponent as TagCrossIcon } from "../../../../../assets/images/tag-cross-icon.svg";
import { ReactComponent as TutorIcon } from "../../../../../assets/images/tutor-icon.svg";
import { ReactComponent as BackArrowIcon } from "../../../../../assets/images/user-profile.svg";
import { ReactComponent as WindowIcon } from "../../../../../assets/images/window-icon.svg";
import { DeleteCannedResponse } from "../../../../Broadcast/WhatsApp/Templates/TemplatesListing";
import {
  ContactWrapper,
  LeadPageWrapper,
  MessagesTable
} from "../../../LeadsStyles";

const LeadTask = () => {
  const source = [{ name: "Web" }];
  // const { isShowing, toggle } = useModal();
  const useModal1 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal1: isShowing, toggleModal1: toggle };
  };

  const useModal2 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal2: isShowing, toggleModal2: toggle };
  };
  const useModal3 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal3: isShowing, toggleModal3: toggle };
  };
  const useModal4 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal4: isShowing, toggleModal4: toggle };
  };
  const useModal5 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal5: isShowing, toggleModal5: toggle };
  };
  const { isShowingModal1, toggleModal1 } = useModal1();
  const { isShowingModal2, toggleModal2 } = useModal2();
  const { isShowingModal3, toggleModal3 } = useModal3();
  const { isShowingModal4, toggleModal4 } = useModal4();
  const { isShowingModal5, toggleModal5 } = useModal5();

  const whatsapp = [{ name: "Yes" }, { name: "no" }];
  const initialValue = {
    facebook: "",
    twitter: "",
    linkdin: "",
  };

  return (
    <ContactWrapper>
      <div className="leadsPageWrapper">
        <div className="header">
          <button className="btn">
            <BackArrowIcon />
          </button>
          <h3>User Profile</h3>
        </div>

        {/* <LeadPageSideBar />  */}
        <div className="userprofile-main-div">
          {/* <div className='side-bar'>
                        <ul>
                            <li><a href="#" className="list-link">Basic Information <img className='img' src={ForwordImg} alt="Basic Information" /></a></li>
                            <li><a href="#" className="list-link">Communication Stats <img className='img' src={ForwordImg} alt="Basic Information" /></a></li>
                            <li><a href="#" className="list-link">Source Details<img className='img' src={ForwordImg} alt="Basic Information" /></a></li>
                            <li><a href="#" className="list-link">Marketing Status<img className='img' src={ForwordImg} alt="Basic Information" /></a></li>
                            <li><a href="#" className="list-link">More Information<img className='img' src={ForwordImg} alt="Basic Information" /></a></li>
                            <li><a href="#" className="list-link">System Summary & Activities<img className='img' src={ForwordImg} alt="Basic Information" /></a></li>
                            <li><a href="#" className="list-link">Shopify Info<img className='img' src={ForwordImg} alt="Basic Information" /></a></li>
                            <li><a href="#" className="list-link">User Timeline<img className='img' src={ForwordImg} alt="Basic Information" /></a></li>
                            <li><a href="#" className="list-link">Web Track<img className='img' src={ForwordImg} alt="Basic Information" /></a></li>
                            <li><a href="#" className="list-link">Files<img className='img' src={ForwordImg} alt="Basic Information" /></a></li>
                            <li><a href="#" className="list-link">Notes<img className='img' src={ForwordImg} alt="Basic Information" /></a></li>
                            <li><a href="#" className="list-link active">Tasks<img className='img' src={ForwordImg} alt="Basic Information" /></a></li>
                            <li><a href="#" className="list-link">Chat Transcript<img className='img' src={ForwordImg} alt="Basic Information" /></a></li>
                        </ul>

                    </div> */}
          {/* <ContactsSidebar /> */}
          <LeadPageWrapper>
            <div className="top-bar">
              <div className="userprofile-details">
                <button className="avtar">
                  <ProfileIcon />
                </button>

                <div className="user-name">
                  Shrikant Dange
                  <div className="social-media-list">
                    <a href="#">
                      <FacebookIcon />
                    </a>
                    <a href="#">
                      <TutorIcon />
                    </a>
                    <a href="#">
                      <LinkdinIcon />
                    </a>

                    <button onClick={() => toggleModal4()} className="edit-btn">
                      {" "}
                      <EditIcon />
                    </button>
                  </div>
                </div>
              </div>

              <div className="user-icons-button">
                <IconButton>
                  <PhoneIcon />
                </IconButton>
                <IconButton>
                  <MailIcon />
                </IconButton>
                <IconButton>
                  <MsgIcon />
                </IconButton>
                <IconButton>
                  <CopyIcon />
                </IconButton>
              </div>
              <div className="assign-user">
                <div className="assign-name">
                  <p>Assigned to</p>
                  <button onClick={() => toggleModal5()} className="edit-btn">
                    <EditIcon />
                  </button>
                </div>
                <div className="icons-listing">
                  <h4>Shubham Puranik</h4>

                  <div className="icon-details">
                    <LocationIcon />

                    <p> Pune, Maharashtra</p>
                  </div>

                  <div className="icon-details">
                    <ChromeIcon />
                    Chrome 97.0
                  </div>

                  <div className="icon-details">
                    <WindowIcon />
                    Windows 10
                  </div>

                  <div className="icon-details">
                    <ScreenIcon />
                    1920 x 1080
                  </div>
                </div>
              </div>
            </div>

            <div className="contact-task">
              <div className="task-heading">Tasks</div>
              <div className="task-buttons">
                <AppButton variant="text" className="app-button-class">
                  All <span className="task-button-span">2</span>
                </AppButton>
                <AppButton variant="text" className="app-button-class">
                  upcoming<span className="task-button-span">2</span>
                </AppButton>
                <AppButton variant="text" className="app-button-class">
                  Completed<span className="task-button-span">2</span>
                </AppButton>
                <AppButton onClick={() => toggleModal3()}>
                  <AppMaterialIcons iconName="add" />
                  Add task
                </AppButton>
              </div>
            </div>
            <MessagesTable>
              <div className="table-header">
                <div className="heading-text">
                  <h4>task</h4>
                </div>
                <div className="heading-text">
                  <h4>created by</h4>
                </div>
                <div className="heading-text">
                  <h4>due date</h4>
                </div>
                <div className="heading-text">
                  <h4>outcome</h4>
                </div>
              </div>
              <div className="table-data">
                <div className="table-row__content">
                  <div className="table-content">
                    <div className="table-content__text">
                      <p>
                        <a href="#">Follow up</a>
                      </p>
                    </div>
                    <div className="table-content__text">
                      <p>Shrikant dange</p>
                    </div>
                    <div className="table-content__text">
                      <p>22 Oct 2022</p>
                    </div>
                    <div className="table-content__text">
                      <p>interested</p>
                    </div>
                    <div className="table-content__text">
                      <div className="table-actions">
                        <AppButton
                          onClick={() => toggleModal2()}
                          variant="outline"
                          className="custom-app-button"
                          style={{ width: "153px", padding: "0px" }}
                        >
                          <AppMaterialIcons iconName="Check" />
                          Mark complete
                        </AppButton>
                        <IconButton onClick={() => toggleModal3()}>
                          <AppMaterialIcons iconName="EditOutlined" />
                        </IconButton>
                        <IconButton
                          onClick={() => toggleModal1()}
                          className="delete-icon"
                          style={{ color: "#7E8392" }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="table-row__content">
                  <div className="table-content">
                    <div className="table-content__text">
                      <p>
                        <a href="#">Follow up</a>
                      </p>
                    </div>
                    <div className="table-content__text">
                      <p>Shrikant dange</p>
                    </div>
                    <div className="table-content__text">
                      <p>22 Oct 2022</p>
                    </div>
                    <div className="table-content__text">
                      <p>interested</p>
                    </div>
                    <div className="table-content__text">
                      <div className="table-actions">
                        <AppButton
                          onClick={() => toggleModal2()}
                          variant="outline"
                          className="custom-app-button"
                          style={{ width: "153px", padding: "0px" }}
                        >
                          <IconButton>
                            <OpenIcon />
                          </IconButton>
                          Mark open
                        </AppButton>
                        <IconButton onClick={() => toggleModal3()}>
                          <AppMaterialIcons iconName="EditOutlined" />
                        </IconButton>
                        <IconButton
                          onClick={() => toggleModal1()}
                          className="delete-icon"
                          style={{ color: "#7E8392" }}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </MessagesTable>
          </LeadPageWrapper>
        </div>
      </div>

      <AppModel
        isShowing={isShowingModal1}
        onClose={() => toggleModal1()}
        divStyle={{
          width: "550px",
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <DeleteCannedResponse>
          <div className="header">
            <div className="header-left">
              <div className="danger">
                <img src={Danger} alt="" />
              </div>
              <div className="text">
                <h4>Delete The Task</h4>
                <p>Are you sure, you want to delete?</p>
              </div>
            </div>
            <div className="header-right">
              <span onClick={() => toggleModal1()}>
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="footer">
            <AppButton variant="grey" onClick={() => toggleModal1()} style={{}}>
              Cancel
            </AppButton>
            <AppButton
              variant="danger-filled"
              onClick={() => toggleModal1()}
              style={{}}
            >
              <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              Delete
            </AppButton>
          </div>
        </DeleteCannedResponse>
      </AppModel>

      <AppModel
        isShowing={isShowingModal2}
        onClose={() => toggleModal2()}
        divStyle={{
          width: "671px",
          height: "371px",
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <LeadPageWrapper>
          <div className="mark-complete">
            <div className="mark-complete-text-class">Mark complete</div>
            <div className="popup-heading-icon">
              <IconButton onClick={() => toggleModal2()}>
                <TagCrossIcon />
              </IconButton>
            </div>
          </div>
          <AppForm initialValues={initialValue} onSubmit={() => { }}>
            <div className="input">
              <AppFormSelectField
                label="Outcome"
                options={whatsapp}
                name="source"
                placeholder="Select an outcome"
                Options={undefined}
              />
            </div>
            <AppFormTextArea
              divStyle={{ height: "111px" }}
              label="Description"
              // name="Description about the task"
              placeholder="Description about the task"
              name={"Description about the task"}
            />
            <div className="mark-complete-buttons">
              <AppButton onClick={() => toggleModal2()} variant="grey">
                cancel
              </AppButton>
              <AppButton onClick={() => toggleModal2()}>done</AppButton>
            </div>
          </AppForm>
        </LeadPageWrapper>
      </AppModel>

      <AppModel
        isShowing={isShowingModal3}
        onClose={() => toggleModal3()}
        divStyle={{
          width: "974px",
          height: "521px",
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <LeadPageWrapper>
          <div className="mark-complete">
            <div className="popup-header">
              <button className="btn">
                <BackArrowIcon />
              </button>
              <div className="mark-complete-text">Add task</div>
            </div>
            <div className="popup-heading-icon">
              <IconButton onClick={() => toggleModal3()}>
                <TagCrossIcon />
              </IconButton>
            </div>
          </div>
          <AppForm initialValues={initialValue} onSubmit={() => { }}>
            <div className="popup-addtask">
              <div className="mark-popup-left-side">
                <div className=" ">
                  <AppFormField
                    label="Title*"
                    name="original_source"
                    placeholder="task title "
                  />
                </div>

                <AppFormTextArea
                  label="Description"
                  placeholder="Description about the task"
                  name={"Description about the task"}
                />
                <div className="date-time-picker">
                  <div className="input">
                    <AppFormSelectField
                      label="Task type"
                      options={whatsapp}
                      name="source"
                      placeholder="Select an outcome"
                      Options={undefined}
                    />
                  </div>
                  <AppFormDatePicker
                    className="app-form-date-picker"
                    name={"source"}
                    label="Due Date"
                  >
                    21/12/2023
                  </AppFormDatePicker>
                  <AppFormTimePickerMui label="Due time" name={""}>
                    12:30
                  </AppFormTimePickerMui>
                </div>
                <div className=" xyz">
                  <AppFormSelectField
                    label="Outcome"
                    options={whatsapp}
                    name="source"
                    placeholder="Select an outcome"
                    Options={undefined}
                  />
                </div>
              </div>
              <div className="mark-popup-right-side">
                <div className="input-add-task">
                  <AppFormSelectField
                    label="created by"
                    options={whatsapp}
                    name="source"
                    placeholder="Select an outcome"
                    Options={undefined}
                  />
                </div>
                <div className="input-add-task">
                  <AppFormSelectField
                    label="Related to"
                    options={whatsapp}
                    name="source"
                    placeholder="Select an outcome"
                    Options={undefined}
                  />
                </div>
                <div className="task-tag">
                  <span className="task-span">
                    Shubham Puranik
                    <IconButton className="task-tag-button">
                      <TagCrossIcon />
                    </IconButton>
                  </span>
                </div>
              </div>
            </div>
            <div className="mark-complete-buttons">
              <AppButton onClick={() => toggleModal3()} variant="grey">
                cancel
              </AppButton>
              <AppButton onClick={() => toggleModal3()}>done</AppButton>
            </div>
          </AppForm>
        </LeadPageWrapper>
      </AppModel>

      <AppModel
        isShowing={isShowingModal4}
        onClose={() => toggleModal4()}
        divStyle={{
          width: "671px",
          //   height:'371px',
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <LeadPageWrapper>
          <div className="social-media-popup">
            <div className="popup-header">
              <div className="popup-heading-text">
                <h4>Edit social profile</h4>
              </div>
              <div className="popup-heading-icon">
                <IconButton onClick={() => toggleModal4()}>
                  <TagCrossIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <AppForm initialValues={initialValue} onSubmit={() => { }}>
            <div className="input">
              <AppFormField
                label="Facebook"
                placeholder="facebook.com/shrikant202"
                name="facebook"
              />
            </div>
            <div className="input">
              <AppFormField
                label="Twitter"
                name="twitter"
                placeholder="Twitter.com/shrikant202 "
              />
            </div>
            <div className="input">
              <AppFormField label="Linked In" name="linkdin" placeholder=" " />
            </div>

            <div className="mark-complete-buttons">
              <AppButton onClick={() => toggleModal4()} variant="grey">
                cancel
              </AppButton>
              <AppButton onClick={() => toggleModal4()}>
                <IconButton>
                  <SaveIcon />
                </IconButton>
                save
              </AppButton>
            </div>
          </AppForm>
        </LeadPageWrapper>
      </AppModel>

      <AppModel
        isShowing={isShowingModal5}
        onClose={() => toggleModal5()}
        divStyle={{
          width: "671px",
          //   height:'371px',
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <LeadPageWrapper>
          <div className="social-media-popup">
            <div className="popup-header">
              <div className="popup-heading-text">
                <h4>Edit Assigned to</h4>
              </div>
              <div className="popup-heading-icon">
                <IconButton onClick={() => toggleModal5()}>
                  <TagCrossIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <AppForm initialValues={initialValue} onSubmit={() => { }}>
            <div className="input">
              <AppFormSelectField
                label="Assigned to"
                options={whatsapp}
                name="source"
                placeholder="Select an outcome"
                Options={undefined}
              />
            </div>

            <div className="mark-complete-buttons">
              <AppButton onClick={() => toggleModal5()} variant="grey">
                cancel
              </AppButton>
              <AppButton onClick={() => toggleModal5()}>
                <IconButton>
                  <SaveIcon />
                </IconButton>
                save
              </AppButton>
            </div>
          </AppForm>
        </LeadPageWrapper>
      </AppModel>
    </ContactWrapper>
  );
};

export default LeadTask;
