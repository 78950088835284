//  vendors
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// components
import Icon from "../Components/Icon";

interface Props {
  id?: number;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  customStyle?:any;
}

const AddNodeButton = (props: Props) => {
  return (
    <Box sx={{ my: "32px", display: "flex", justifyContent: "center",...props.customStyle}}>
      <IconButton
        sx={{
          p: "9px",
          backgroundColor: "primary.main",
          "&.Mui-disabled": {
            backgroundColor: "default.grey",
            cursor: "not-allowed",
            pointerEvents: "auto",
          },
        }}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <Icon icon="add" size={14} color={"common.white"} disableFill />
      </IconButton>
    </Box>
  );
};
export default AddNodeButton;
