import styled from "styled-components";
import { theme } from "../../../Customization/Theme";
import { device } from "../../../Customization/BreakPoints";
// Css main Layout
export const WrapperContainer = styled.div`
  width: 100%;
  /* height: calc(100% - 64px); */
  height: 100%;
  form {
    padding: 25px;
  }
`;

export const Title = styled.span`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: ${theme.typography.h4.fontWeight};
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #323232;
  }
`;

export const Section = styled.span`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: ${theme.typography.h6.fontWeight};
    font-size: 1rem;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #323232;
  }
`;
