import {
  Box,
  DialogActions,
  DialogTitle,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../Customization/Theme";
import CloseIcon from "@mui/icons-material/Close";
import * as MUI from "@mui/material";
import AppButton from "../../AppComponents/AppButton";
import AppIcon from "../../AppComponents/AppIcon";
import { AppMaterialIcons } from "../../AppComponents/AppMaterialIcons";
import AppUserDropdown from "../../AppComponents/AppUserDropdown";
import { ProtectedAppButton } from "../../AppComponents/ProtectedComponents";
import { AppTitle } from "../../Customization/CommonElements";
import {
  HocBackdropLoader,
  HocBackdropLoaderProps,
} from "../../HOC/HocBackdropLoader";
import { useNotification } from "../../Hooks/useNotification/useNotification";
import { topUserMenuOptions } from "../../Models/defaultSelectOptions";
import { ChatbotConsoleService } from "../../Services/Apis/ChatbotConsoleService";
import { CoreService } from "../../Services/Apis/CoreApis";
import { useAppDispatch, useAppSelector } from "../../Store";
import { refreshAccessToken, useAuthStore } from "../../Store/Slices/AuthSlice";
import {
  setIsUserAvailable,
  setUserCreditsInfo,
  useCurrentUser,
} from "../../Store/Slices/CurrentUser/CurrentUserSlice";
import { SubscriptionsNetworkService } from "../../Store/Slices/Subscriptions/subscriptionsNetworkService";
import { ReactComponent as UserAvatar } from "../../assets/images/2.svg";
import { ReactComponent as BroadcastIcon } from "../../assets/images/BroadcastIcon.svg";
import logo from "../../assets/images/logo.svg";
import { ReactComponent as WalletImage } from "../../assets/images/wallet.svg";
import { useSocket } from "../../socket/hooks/socket-context";
import { SOCKET_CONNECTION_TYPES } from "../../socket/utils/Enum";
import { AppToolTip } from "../UiComponents/AppToolTip/AppToolTip";
import AddWallet from "./AddWallet";
import { StyledWalletDialog, Wallet } from "./MenuStyle";

interface TopMenuProps extends HocBackdropLoaderProps {
  fullWidth?: any;
  showLogo?: boolean;
  theme?: any;
}

const TopMenu = (props: TopMenuProps) => {
  const navigate = useNavigate();
  const { data: authData } = useSelector(useAuthStore);
  const { userConfig } = useSelector(useAuthStore);
  const { isUserAvailable } = useAppSelector(useCurrentUser);
  const dispatch = useAppDispatch();
  const { userCreditsInfo } = useAppSelector(useCurrentUser);
  const [check, setCheck] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showCreditModal, setShowCreditModal] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const { showAlert } = useNotification();
  const { socket } = useSocket();
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    // props.setLoading(false);
    CoreService.getCredits().then((response) => {
      const reversedItems = response?.data;
      if (
        reversedItems.remaining_amount < 100 &&
        !localStorage.getItem("has_low_balance")
      ) {
        setShowCreditModal(true);
        localStorage.setItem("has_low_balance", "true");
      } else {
        localStorage.removeItem("has_low_balance");
      }
      dispatch(setUserCreditsInfo(reversedItems));
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const redirectToHome = () => {
    const url = "/";
    navigate(url);
  };

  const addBalance = (balance: number) => {
    props.setLoading(true);
    setCheck(false);
    let data = {
      subscription_id: userConfig?.payload?.subscription_id,
      addons: [
        {
          addon_code: "wallet",
          quantity: balance,
        },
      ],
      redirect_url: "/subscription/success",
    };
    SubscriptionsNetworkService.addCredits(
      data,
      userConfig?.payload?.subscription_id,
    )
      .then((res: any) => {
        window.open(res?.data?.hostedpage?.url, "_self");
      })
      .catch((err: any) => {
        props.setLoading(false);
      })
      .finally(() => {
        props.setLoading(false);
      });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props?.setLoading(true);
    let data = {
      user_id: authData?.data?.user?.id,
      workspace_id: authData?.data?.user?.workspace_id,
      online_status: event.target.checked ? true : false,
    };

    ChatbotConsoleService.agentOnlineStatus(data)
      .then((res: any) => {
        refreshAccessToken(dispatch)(res)
          .then((data: any) => {
            dispatch(setIsUserAvailable(event.target.checked));
            socket?.emit(
              SOCKET_CONNECTION_TYPES.ONLINE_AGENTS_STATUS_CHANGED,
              data,
              (error: any, response: any) => { },
            );
          })
          .catch((err: any) => {
            console.error("refreshAccessToken Error - ", err);
            showAlert(err.response?.data || "Something went wrong", "error");
          });
      })
      .catch((err: any) => {
        console.error("agent error", err);
      })
      .finally(() => {
        props?.setLoading(false);
      });
  };
  return (
    <TopMenuWrap fullWidth={props.fullWidth}>
      <TopMenuLeftBox>
        {props.showLogo && (
          <img src={logo} onClick={() => redirectToHome()} alt="logo" />
        )}
        <AppTitle onClick={() => redirectToHome()}> Geta</AppTitle>
      </TopMenuLeftBox>
      <TopMenuRightBox>
        {userCreditsInfo &&
          userCreditsInfo?.conversations &&
          !userCreditsInfo?.conversations?.enabled ? (
          <div className="conversationLimitBox">
            {userCreditsInfo?.conversations?.message}
          </div>
        ) : null}

        <Wallet>
          <WalletImage />
          <p className="creditsTitle">
            Available Wallet Balance:{" "}
            <span className="credits">
              {parseFloat(userCreditsInfo?.remaining_amounts)?.toFixed(2) || 0}
            </span>
          </p>
          <ProtectedAppButton
            moduleId="add_credits_to_wallet"
            style={{ width: "25%", margin: "0px 8px" }}
            onClick={() => {
              setCheck(true);
            }}
          >
            Add Balance
          </ProtectedAppButton>
        </Wallet>

        <SwitchToggle>
          <div
            style={{
              padding: "8px 1rem",
              overflow: "hidden",
              display: "flex",
            }}
          >
            <FormControlLabel
              control={
                <CustomSwitch
                  checked={isUserAvailable}
                  onChange={handleChange}
                  name="userOnline"
                  size="small"
                />
              }
              label={""}
            />
            <FormHelperText
              style={{
                fontSize: "16px",
                margin: "3px -4px 2px",
                width: "48px", //added to skip layout shift
              }}
            >
              {isUserAvailable ? "Online" : " Away "}
            </FormHelperText>
            <AppToolTip
              tooltipProps={{
                title:
                  "When set to 'Offline', no chats will be assigned. Toggle to 'Available' to start receiving chats again",
                placement: "top",
              }}
              iconButtonProps={{ disableRipple: true }}
              IconComponent={
                <AppMaterialIcons
                  iconName="HelpOutline"
                  style={{ fontSize: "16px" }}
                />
              }
            />
          </div>
        </SwitchToggle>
        <div
          onClick={(e) => handleClick(e)}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "14px",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow:
                "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          >
            <UserAvatar />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="6px"
          >
            <Typography variant="subtitle1">
              {authData &&
                authData.data &&
                authData.data.user &&
                authData.data.user.first_name}
            </Typography>
            <AppIcon
              size="22px"
              color={theme.palette.default.darkGrey}
              title="ri-arrow-down-s-line"
            />
          </Box>
        </div>
      </TopMenuRightBox>

      <AppUserDropdown
        open={open}
        setAnchorEl={setAnchorEl}
        anchorEl={anchorEl}
        data={topUserMenuOptions}
      />
      <AddWallet check={check} setCheck={setCheck} addBalance={addBalance} />
      <StyledWalletDialog
        open={showCreditModal}
        onClose={() => {
          setShowCreditModal(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="walletHeader" sx={{ m: 0, p: 2 }}>
          Credits Alert
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            setShowCreditModal(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle
          className="walletTitle"
          sx={{
            m: 0,
            p: 2,
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <BroadcastIcon />
          <div>
            <h3>LOW BALANCE ALERT!</h3>
            <p
              style={{
                maxWidth: "500px",
                marginTop: "7px",
                fontSize: "16px",
                fontWeight: "300",
                textTransform: "none",
                lineHeight: "22px",
              }}
            >
              Your wallet account balance is less than 100 Rs. Please recharge
              immediately for uninterrupted services.
            </p>
          </div>
        </DialogTitle>

        <DialogActions>
          <AppButton
            moduleId="add_credits_to_wallet"
            onClick={() => {
              setShowCreditModal(false);
            }}
            style={{ margin: "10px 8px", padding: "20px 40px" }}
          >
            Got it
          </AppButton>
        </DialogActions>
      </StyledWalletDialog>
    </TopMenuWrap>
  );
};

export default HocBackdropLoader(TopMenu);

const TopMenuLeftBox = styled.div`
  && {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    flex: 4;
    img {
      cursor: pointer;
    }
    p {
      cursor: pointer;
    }
  }
`;
const TopMenuRightBox = styled.div`
  && {
    display: flex;
    justify-content: right;
    gap: 2rem;
    align-items: center;

    .conversationLimitBox {
      color: ${theme.palette.default.darkGrey};
      font-weight: 400;
      font-size: 14px;
    }
  }
`;

export const UserMenuBox = styled(Paper)`
  && {
    position: absolute;
    top: 100%;
    right: 2%;
  }
`;

export const TopMenuWrap = styled.div<TopMenuProps>`
  && {
    background-color: ${theme.palette.default.white};
    z-index: 999;
    height: 4rem;
    width: ${(props: any) => (props?.fullWidth ? `100%` : "calc(100% - 60px)")};
    padding: 0 1rem;
    position: -webkit-fixed;
    position: fixed;
    right: 0;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
  }
`;
const SwitchToggle = styled.div`
  // && {
  //   & .MuiSwitch-root {
  //     marginright: 0.6rem;
  //   }
  //   & .MuiFormControlLabel-root {
  //     marginleft: -4px;
  //   }
  // }
`;

const CustomSwitch = MUI.styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",

  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 4,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#FFFFFF",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#008000",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 8,
    height: 8,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
  "& .MuiFormControlLabel-root": {
    marginLeft: "-4px",
  },
}));
