import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ContactsActions } from "./contacts.action";
// import { broadcastActions } from "./broadcast.actions";

interface initialStateTypes {
  data: {
    filteredData: any;
    contactsListData: any;
    contactsSelectData: any;
    pagination: any;
    contactsFields: [];
    fieldCheck: any;
    addContactsDropDownList: [];
    unDependantContactsDropDownList: [];
    licfeCycleConatcts: any;
    lostReasonData: any;
    contactTagsList: [];
    assigneeList: any[];
    bulkUpdateFields: [];
    addTolistData: [];
    gridData: [];
    cardDetails: [];
    seeMore: [];
    delete: any;
    ContactById: {};
    exportedContacts: [];
    editByIdContactsField: any[];
    sequence: any[];
    filterQuery: any;
    savedFilterQuery: any;
    BasicInformation: {};
    Communication: {};
    SourceData: {};
    marketingInfo: {};
    activity: {};
    files: {};
    chatTransScript: {};
    addNotes: any;
    editId: string;
    singleContactTagsList: any[];
    sectionData: any;
    fieldsData: any;
    fileUploadedSuccess: any;
    contactData: any;
    sectionValues: any;
    notes: any;
    canAddNotes: any;
    resetFilterQuery: any;
    totalNoteCount: any;
    marketingStatusOption: any;
    hasGlobalAccess: string;
    contactsTableSorting: {};
    fieldSectionData: any;
    contactHistoryData: any[];

    //views
    viewsListing: any[];
    enabledViewsListing: any[];
    contactsFilterDrawerStatus: boolean;
    selectedContactView: any;
    contactFieldsData: any[];
    contactFieldHistoryData: any[];
    contactFieldHistoryDataPagination: any;
    contactFieldHistoryDataLoading: boolean;
    contactFieldHistoryDataError: boolean;
  };
  error: string;
  loading: boolean;
}

const initialState: initialStateTypes = {
  data: {
    filteredData: null,
    contactsListData: [],
    contactsSelectData: null,
    pagination: null,
    contactsFields: [],
    fieldCheck: [],
    addContactsDropDownList: [],
    unDependantContactsDropDownList: [],
    licfeCycleConatcts: [],
    lostReasonData: {},
    contactTagsList: [],
    assigneeList: [],
    bulkUpdateFields: [],
    addTolistData: [],
    gridData: [],
    cardDetails: [],
    seeMore: [],
    delete: null,
    ContactById: {},
    exportedContacts: [],
    editByIdContactsField: [],
    sequence: [],
    filterQuery: null,
    savedFilterQuery: null,
    BasicInformation: {},
    Communication: {},
    SourceData: {},
    marketingInfo: {},
    activity: {},
    files: {},
    chatTransScript: {},
    addNotes: {},
    editId: "",
    singleContactTagsList: [],
    sectionData: null,
    fieldsData: null,
    fileUploadedSuccess: null,
    contactData: {},
    sectionValues: null,
    notes: [],
    canAddNotes: {},
    resetFilterQuery: null,
    totalNoteCount: 0,
    marketingStatusOption: [],
    hasGlobalAccess: "",
    contactsTableSorting: {},
    fieldSectionData: [],
    contactHistoryData: [],
    viewsListing: [],
    enabledViewsListing: [],
    contactsFilterDrawerStatus: false,
    selectedContactView: null,
    contactFieldsData: [],
    contactFieldHistoryData: [],
    contactFieldHistoryDataPagination: {},
    contactFieldHistoryDataLoading: false,
    contactFieldHistoryDataError: false,
  },
  error: "",
  loading: false,
};
const ContactsSlice = createSlice({
  name: "ContactsSlice",
  initialState,
  reducers: {
    setFieldCheck: (state: any, action: PayloadAction<any>) => {
      state.data.fieldCheck = action.payload;
      return state;
    },
    kanBanCardDetails(state, { payload }: any) {
      state.data.cardDetails = payload;
    },
    SeeMorePaginationData(state, { payload }: any) {
      state.data.seeMore = [];
    },
    DeleteData(state, { payload }: any) {
      state.data.delete = null;
    },
    setFilterQuery(state, { payload }: any) {
      state.data.filterQuery = payload;
    },
    UnmountEditContact(state) {
      state.data.editByIdContactsField = [];
    },
    setNotesData(state: any, action: PayloadAction<any>) {
      state.data.addNotes = action.payload;
    },
    editId(state, action: PayloadAction<any>) {
      state.data.editId = action.payload;
    },
    resetfileUploadedSuccess(state, action: PayloadAction<any>) {
      state.data.fileUploadedSuccess = false;
    },
    setViewsListing(state, action: PayloadAction<any>) {
      state.data.viewsListing = [];
    },
    setContactsFilterDrawerStatus(state, { payload }: PayloadAction<any>) {
      state.data.contactsFilterDrawerStatus = payload;
    },
    setSelectedContactView(state, { payload }: PayloadAction<any>) {
      state.data.selectedContactView = payload;
    },
    resetContactsPaginationData(state, { payload }: PayloadAction<any>) {
      state.data.pagination = {
        currentPage: 1,
        perPage: 50,
        totalPages: 0,
        totalRecords: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder

      //  GET Contacts List Data
      .addCase(ContactsActions.getContactsListData.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      // action.payload?.data?.results;
      .addCase(
        ContactsActions.getContactsListData.fulfilled,
        (state, action) => {
          let updatedData = action.payload.data?.data
          state.data.contactsListData = updatedData;
          state.data.pagination = action.payload.data?.pagination;
          state.data.sequence = action.payload.data?.columns;
          // state.data.filterQuery = action.payload.data?.filter_query;
          state.data.resetFilterQuery = action.payload.data?.filter_query;
          state.data.savedFilterQuery = action.payload.data?.filter_query;
          // state.data.contactsListData = action?.payload?.data?.count;
          state.data.hasGlobalAccess = action.payload.data?.update_access;
          state.data.contactsTableSorting =
            action.payload.data?.sorting_object || {};
          state.loading = false;
        },
      )
      .addCase(
        ContactsActions.getContactsListData.rejected,
        (state, action) => {
          state.loading = false;
          state.data.contactsListData = null;
          state.error = action?.error?.message || "";
        },
      )

      //  GET Contacts List Data
      .addCase(
        ContactsActions.getContactsSelectData.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      // action.payload?.data?.results;
      .addCase(
        ContactsActions.getContactsSelectData.fulfilled,
        (state, action) => {
          state.data.contactsSelectData = action.payload.data?.data;
          // state.data.contactsListData = action?.payload?.data?.count;
          state.loading = false;
        },
      )
      .addCase(
        ContactsActions.getContactsSelectData.rejected,
        (state, action) => {
          state.loading = false;
          state.data.contactsSelectData = null;
          state.error = action?.error?.message || "";
        },
      )

      //  GET ContactsFields
      .addCase(
        ContactsActions.getAddContactsFields.pending,
        (state, action) => {
          state.error = "";
          // state.loading = true;
        },
      )
      // action.payload?.data?.results;
      .addCase(
        ContactsActions.getAddContactsFields.fulfilled,
        (state, action) => {
          state.data.contactsFields = action.payload.data?.data;
          // state.loading = false;
          // state.data.contactsListData = action?.payload?.data?.count;
        },
      )
      .addCase(
        ContactsActions.getAddContactsFields.rejected,
        (state, action) => {
          // state.loading = false;
          state.data.contactsFields = [];
          state.error = action?.error?.message || "";
        },
      )

      //  GET edit ContactsFields
      .addCase(
        ContactsActions.getEditContactsFilds.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      // action.payload?.data?.results;
      .addCase(
        ContactsActions.getEditContactsFilds.fulfilled,
        (state, action) => {
          state.data.editByIdContactsField = action.payload.data?.data;
          state.loading = false;
          // state.data.contactsListData = action?.payload?.data?.count;
        },
      )
      .addCase(
        ContactsActions.getEditContactsFilds.rejected,
        (state, action) => {
          state.loading = false;
          state.data.editByIdContactsField = [];
          state.error = action?.error?.message || "";
        },
      )

      //  GET addContactsFields
      .addCase(
        ContactsActions.getAddContactsDropDown.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      // action.payload?.data?.results;
      .addCase(
        ContactsActions.getAddContactsDropDown.fulfilled,
        (state, action) => {
          state.data.addContactsDropDownList = action.payload.data?.data;
          state.loading = false;
        },
      )
      .addCase(
        ContactsActions.getAddContactsDropDown.rejected,
        (state, action) => {
          state.loading = false;
          state.data.addContactsDropDownList = [];
          state.error = action?.error?.message || "";
        },
      )
      //  GET undependent addContactsFields
      .addCase(
        ContactsActions.getContactsDropDownForUndependant.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      // action.payload?.data?.results;
      .addCase(
        ContactsActions.getContactsDropDownForUndependant.fulfilled,
        (state, action) => {
          state.data.unDependantContactsDropDownList =
            action.payload.data?.data?.options;
          state.loading = false;
        },
      )
      .addCase(
        ContactsActions.getContactsDropDownForUndependant.rejected,
        (state, action) => {
          state.loading = false;
          state.data.unDependantContactsDropDownList = [];
          state.error = action?.error?.message || "";
        },
      )

      //  GET addContactsFields
      .addCase(
        ContactsActions.getLifeCycleContactsDropDown.pending,
        (state, action) => {
          state.error = "";
          // state.loading = true;
        },
      )
      // action.payload?.data?.results;
      .addCase(
        ContactsActions.getLifeCycleContactsDropDown.fulfilled,
        (state, action) => {
          state.data.licfeCycleConatcts = action.payload.data?.data;
          state.data.lostReasonData = action.payload.data?.lost_reson_data;
          state.loading = false;
        },
      )
      .addCase(
        ContactsActions.getLifeCycleContactsDropDown.rejected,
        (state, action) => {
          state.loading = false;
          state.data.licfeCycleConatcts = [];
          state.error = action?.error?.message || "";
        },
      )

      //  GET contact by Id
      .addCase(ContactsActions.getContactsByID.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      // action.payload?.data?.results;
      .addCase(ContactsActions.getContactsByID.fulfilled, (state, action) => {
        state.data.ContactById = action.payload.data?.data;
        state.loading = false;
      })
      .addCase(ContactsActions.getContactsByID.rejected, (state, action) => {
        state.loading = false;
        state.data.ContactById = [];
        state.error = action?.error?.message || "";
      })

      //  edit contact by Id
      .addCase(ContactsActions.editContactsByID.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      // action.payload?.data?.results;
      .addCase(ContactsActions.editContactsByID.fulfilled, (state, action) => {
        // state.data.ContactById = action.payload.data?.data;
        state.loading = false;
      })
      .addCase(ContactsActions.editContactsByID.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message || "";
      })

      //  GET Contacts tags List Data
      .addCase(ContactsActions.getContactsTagsList.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        ContactsActions.getContactsTagsList.fulfilled,
        (state, action) => {
          state.data.contactTagsList = action.payload?.data?.response;
          state.loading = false;
        },
      )
      .addCase(
        ContactsActions.getContactsTagsList.rejected,
        (state, action) => {
          state.loading = false;
          state.data.contactTagsList = [];
          state.error = action?.error?.message || "";
        },
      )

      //  add tags List Data
      .addCase(ContactsActions.addTagsList.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.addTagsList.fulfilled, (state, action) => {
        // state.data.contactTagsList = action.payload?.data?.response;
        state.loading = false;
      })
      .addCase(ContactsActions.addTagsList.rejected, (state, action) => {
        state.loading = false;

        state.error = action?.error?.message || "";
      })

      //  GET add to  List Data
      .addCase(ContactsActions.getAddToList.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.getAddToList.fulfilled, (state, action) => {
        state.data.addTolistData = action.payload?.data?.results;
        state.loading = false;
      })
      .addCase(ContactsActions.getAddToList.rejected, (state, action) => {
        state.loading = false;
        state.data.addTolistData = [];
        state.error = action?.error?.message || "";
      })

      //  add to List post
      .addCase(ContactsActions.addToList.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.addToList.fulfilled, (state, action) => {
        // state.data.contactTagsList = action.payload?.data?.response;
        state.loading = false;
      })
      .addCase(ContactsActions.addToList.rejected, (state, action) => {
        state.loading = false;

        state.error = action?.error?.message || "";
      })

      //  GET Contacts tags List Data
      .addCase(ContactsActions.getAssigneeList.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.getAssigneeList.fulfilled, (state, action) => {
        state.data.assigneeList = action.payload?.data?.data;
        state.loading = false;
      })
      .addCase(ContactsActions.getAssigneeList.rejected, (state, action) => {
        state.loading = false;
        state.data.assigneeList = [];
        state.error = action?.error?.message || "";
      })

      //  add assignee List Data
      .addCase(ContactsActions.addAssignee.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.addAssignee.fulfilled, (state, action) => {
        // state.data.contactTagsList = action.payload?.data?.response;
        state.loading = false;
      })
      .addCase(ContactsActions.addAssignee.rejected, (state, action) => {
        state.loading = false;

        state.error = action?.error?.message || "";
      })

      //  bulk update contacts
      .addCase(ContactsActions.bulkUpdateContact.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.bulkUpdateContact.fulfilled, (state, action) => {
        // state.data.contactTagsList = action.payload?.data?.response;
        state.loading = false;
      })
      .addCase(ContactsActions.bulkUpdateContact.rejected, (state, action) => {
        state.loading = false;

        state.error = action?.error?.message || "";
      })

      //  bulk update contacts
      .addCase(ContactsActions.getBulkUpdateFields.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        ContactsActions.getBulkUpdateFields.fulfilled,
        (state, action) => {
          state.data.bulkUpdateFields = action.payload?.data?.data;
          state.loading = false;
        },
      )
      .addCase(
        ContactsActions.getBulkUpdateFields.rejected,
        (state, action) => {
          state.loading = false;

          state.error = action?.error?.message || "";
        },
      )

      //  bulk delete contacts
      .addCase(ContactsActions.getContactGridView.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        ContactsActions.getContactGridView.fulfilled,
        (state, action) => {
          state.data.gridData = action.payload?.data?.data;
          state.loading = false;
          // state.data.filterQuery = action.payload?.data?.filter_query;
        },
      )
      .addCase(ContactsActions.getContactGridView.rejected, (state, action) => {
        state.loading = false;

        state.error = action?.error?.message || "";
      })

      //  contact grid status  update
      .addCase(
        ContactsActions.ContactGridViewUpdate.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        ContactsActions.ContactGridViewUpdate.fulfilled,
        (state, action) => {
          state.data.bulkUpdateFields = action.payload?.data?.data;
          state.loading = false;
        },
      )
      .addCase(
        ContactsActions.ContactGridViewUpdate.rejected,
        (state, action) => {
          state.loading = false;

          state.error = action?.error?.message || "";
        },
      )

      //  contact grid status  update
      .addCase(ContactsActions.GetMoreContacts.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.GetMoreContacts.fulfilled, (state, action) => {
        state.data.seeMore = action.payload?.data;
        state.loading = false;
      })
      .addCase(ContactsActions.GetMoreContacts.rejected, (state, action) => {
        state.loading = false;

        state.error = action?.error?.message || "";
      })

      //  contact grid delete contacts
      .addCase(ContactsActions.DeletContacts.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.DeletContacts.fulfilled, (state, action) => {
        state.data.delete = action.payload?.data?.contacts;
        state.loading = false;
      })
      .addCase(ContactsActions.DeletContacts.rejected, (state, action) => {
        state.loading = false;

        state.error = action?.error?.message || "";
      })

      // // * get list
      .addCase(ContactsActions.GetExportedContacts.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        ContactsActions.GetExportedContacts.fulfilled,
        (state, action) => {
          state.data.exportedContacts = action.payload?.data;
          state.loading = false;
        },
      )
      .addCase(
        ContactsActions.GetExportedContacts.rejected,
        (state, action) => {
          state.loading = false;

          state.error = action?.error?.message || "";
        },
      )

      // // * get data
      .addCase(
        ContactsActions.DownloadExportedContacts.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        ContactsActions.DownloadExportedContacts.fulfilled,
        (state, action) => {
          // state.data.delete = action.payload?.data?.contacts;
          state.loading = false;
        },
      )
      .addCase(
        ContactsActions.DownloadExportedContacts.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        },
      )

      // // * get basic info
      .addCase(ContactsActions.getBasicInfo.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.getBasicInfo.fulfilled, (state, action) => {
        state.data.BasicInformation = action.payload?.data;
        state.loading = false;
      })
      .addCase(ContactsActions.getBasicInfo.rejected, (state, action) => {
        state.loading = false;

        state.error = action?.error?.message || "";
      })
      // // * get Communication
      .addCase(ContactsActions.getCommunication.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.getCommunication.fulfilled, (state, action) => {
        state.data.Communication = action.payload?.data?.data;
        state.loading = false;
      })
      .addCase(ContactsActions.getCommunication.rejected, (state, action) => {
        state.loading = false;

        state.error = action?.error?.message || "";
      })

      .addCase(ContactsActions.getSourceDetails.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.getSourceDetails.fulfilled, (state, action) => {
        state.data.SourceData = action.payload?.data;
        state.loading = false;
      })
      .addCase(ContactsActions.getSourceDetails.rejected, (state, action) => {
        state.loading = false;

        state.error = action?.error?.message || "";
      })

      .addCase(
        ContactsActions.getMarketingInformation.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        ContactsActions.getMarketingInformation.fulfilled,
        (state, action) => {
          state.data.marketingInfo = action.payload?.data;
          state.loading = false;
        },
      )
      .addCase(
        ContactsActions.getMarketingInformation.rejected,
        (state, action) => {
          state.loading = false;

          state.error = action?.error?.message || "";
        },
      )

      .addCase(ContactsActions.getActivities.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.getActivities.fulfilled, (state, action) => {
        state.data.activity = action.payload?.data;
        state.loading = false;
      })
      .addCase(ContactsActions.getActivities.rejected, (state, action) => {
        state.loading = false;

        state.error = action?.error?.message || "";
      })

      .addCase(ContactsActions.getFiles.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.getFiles.fulfilled, (state, action) => {
        state.data.files = action.payload?.data;
        state.loading = false;
      })
      .addCase(ContactsActions.getFiles.rejected, (state, action) => {
        state.loading = false;

        state.error = action?.error?.message || "";
      })

      .addCase(ContactsActions.getChatTranscript.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.getChatTranscript.fulfilled, (state, action) => {
        state.data.chatTransScript = action.payload?.data;
        state.loading = false;
      })
      .addCase(ContactsActions.getChatTranscript.rejected, (state, action) => {
        state.loading = false;

        state.error = action?.error?.message || "";
      })
      //  GET Contacts tags List Data By ID
      .addCase(
        ContactsActions.getContactsTagsListByID.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        ContactsActions.getContactsTagsListByID.fulfilled,
        (state, action) => {
          state.data.singleContactTagsList = action.payload?.data?.response;
          state.loading = false;
        },
      )
      .addCase(
        ContactsActions.getContactsTagsListByID.rejected,
        (state, action) => {
          state.loading = false;
          state.data.singleContactTagsList = [];
          state.error = action?.error?.message || "";
        },
      )

      //  GET Contacts Section Data By ID
      .addCase(ContactsActions.getSectionDetails.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.getSectionDetails.fulfilled, (state, action) => {
        state.data.sectionData = action.payload?.data?.data;
        state.loading = false;
        state.data.fieldsData = null;
      })
      .addCase(ContactsActions.getSectionDetails.rejected, (state, action) => {
        state.loading = false;
        state.data.sectionData = [];
        state.error = action?.error?.message || "";
      })

      //  GET Contacts Section Fields By ID
      .addCase(ContactsActions.getSectionFields.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.getSectionFields.fulfilled, (state, action) => {
        state.data.fieldSectionData = action.payload?.data?.data;
        state.loading = false;
        // if (state.data.sectionData?.length) {
        //   action.payload?.data?.data?.[0]?.fields.forEach((field: any) => {
        //     field.value = state.data.sectionData.find(
        //       (sectionField: any) => sectionField.name === field.name
        //     )?.value;
        //   });
        // }
        state.data.contactData = action.payload?.data?.contact_data;
        state.data.sectionValues = action.payload?.data?.contact_data;
        state.data.fieldsData = action.payload?.data?.data;
      })
      .addCase(ContactsActions.getSectionFields.rejected, (state, action) => {
        state.loading = false;
        state.data.fieldsData = [];
        state.error = action?.error?.message || "";
      })

      //  GET Contacts Section Fields By ID
      .addCase(ContactsActions.addFiles.pending, (state, action) => {
        state.error = "";
        state.loading = true;
        state.data.fileUploadedSuccess = null;
      })
      .addCase(ContactsActions.addFiles.fulfilled, (state, action) => {
        // state.data.fileUploadedSuccess = action.payload?.data?.data?.[0]?.fields;
        state.loading = false;
        if (action?.payload?.data?.status) {
          state.data.fileUploadedSuccess = true;
        } else {
          state.data.fileUploadedSuccess = false;
        }
      })
      .addCase(ContactsActions.addFiles.rejected, (state, action) => {
        state.loading = false;
        state.data.fileUploadedSuccess = false;
        state.error = action?.error?.message || "";
      })
      // Get ALL Notes
      .addCase(ContactsActions.getAllNotes.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.getAllNotes.fulfilled, (state, action) => {
        state.data.notes = action.payload?.data?.data;
        state.data.totalNoteCount = action.payload?.data?.total_count;
        state.data.canAddNotes = action.payload?.data;
        state.loading = false;
      })
      .addCase(ContactsActions.getAllNotes.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message || "";
        state.data.totalNoteCount = 0;
      })

      //  Get marketing status option
      .addCase(
        ContactsActions.getMarketingStatusOption.pending,
        (state, action) => {
          state.error = "";
          state.loading = true;
        },
      )
      .addCase(
        ContactsActions.getMarketingStatusOption.fulfilled,
        (state, action) => {
          state.data.marketingStatusOption = action.payload?.data?.data;
          state.loading = false;
        },
      )
      .addCase(
        ContactsActions.getMarketingStatusOption.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        },
      )

      // Contact History Data
      .addCase(ContactsActions.leadsForContact.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(ContactsActions.leadsForContact.fulfilled, (state, action) => {
        state.loading = false;
        state.data.contactHistoryData = action?.payload?.data?.data;
      })
      .addCase(ContactsActions.leadsForContact.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error?.message || "";
      })

      // views contact listing
      .addCase(ContactsActions.getAllContactsViews.pending, (state, action) => {
        state.error = "";
        state.loading = true;
      })
      .addCase(
        ContactsActions.getAllContactsViews.fulfilled,
        (state, action) => {
          state.loading = false;
          state.data.viewsListing = action?.payload?.data?.results;
          state.data.enabledViewsListing = action?.payload?.data?.results
            ?.filter((view: any) => view?.position !== null)
            ?.sort((a: any, b: any) => a?.position - b?.position);
        },
      )
      .addCase(
        ContactsActions.getAllContactsViews.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action?.error?.message || "";
        },
      )

      // Contact Field Hostory
      .addCase(ContactsActions.fieldHistoryAction.pending, (state, action) => {
        state.error = "";
        state.data.contactFieldHistoryDataLoading = true;
        state.data.contactFieldHistoryDataError = false;
      })
      .addCase(
        ContactsActions.fieldHistoryAction.fulfilled,
        (state, action) => {
          state.data.contactFieldHistoryData =
            action?.payload?.data?.data || [];
          state.data.contactFieldHistoryDataPagination =
            action?.payload?.data?.pagination || {};
          state.data.contactFieldHistoryDataLoading = false;
          state.data.contactFieldHistoryDataError = false;
        },
      )
      .addCase(ContactsActions.fieldHistoryAction.rejected, (state, action) => {
        state.error = action?.error?.message || "";
        state.data.contactFieldHistoryDataLoading = false;
        state.data.contactFieldHistoryDataError = true;
      })
      // Get Contact Field
      .addCase(
        ContactsActions.getContactsSelectDataAction.pending,
        (state, action) => {
          state.error = "";
          state.data.contactFieldsData = [];
        },
      )
      .addCase(
        ContactsActions.getContactsSelectDataAction.fulfilled,
        (state, action) => {
          state.data.contactFieldsData = action?.payload;
        },
      )
      .addCase(
        ContactsActions.getContactsSelectDataAction.rejected,
        (state, action) => {
          state.error = action?.error?.message || "";
          state.data.contactFieldsData = [];
        },
      );
  },
});
export const {
  setFieldCheck,
  setContactsFilterDrawerStatus,
  setSelectedContactView,
  resetContactsPaginationData,
} = ContactsSlice.actions;

export default ContactsSlice.reducer;
export const {
  kanBanCardDetails,
  SeeMorePaginationData,
  DeleteData,
  UnmountEditContact,
  setFilterQuery,
  setNotesData,
  editId,
  resetfileUploadedSuccess,
} = ContactsSlice.actions;
