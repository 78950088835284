// RightDrawer.tsx
import React from "react";
import { ContainerStyled } from "../Drawer/drawer.styled";
import { rightDrawerConfig } from "../../Config/RightDrawer";

interface RightDrawerProps {
  componentType: string; // Prop to determine which component to render based on type
  isOpen: boolean; // Prop to control visibility
}

const RightDrawer: React.FC<RightDrawerProps> = ({ componentType, isOpen }) => {
  if (!isOpen) return null; // Return null if isOpen is false

  // Find the component based on the type
  const selectedConfig = rightDrawerConfig.find(
    (config) => config.type === componentType
  );
  const SelectedComponent = selectedConfig?.Component;

  return (
    <ContainerStyled
      style={{
        minWidth: "40%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {SelectedComponent ? (
        <SelectedComponent /> // Render the selected component
      ) : null}
    </ContainerStyled>
  );
};

export default RightDrawer;
