export const ContactSideBarMenu = [
  {
    label: "Basic Information",
    url: "/contacts/view/:contact_id/basic-information",
    isDisabled: false,
    isAvailable: true, //if we want dynamic rendering the pass the correct moduleId and make isAvailable = false
    moduleId: "contacts",
    parentModuleId: "contacts",
    value: "basic-information",
  },
  {
    label: "Communication Stats",
    url: "/contacts/view/:contact_id/communication-stats",
    isDisabled: false,
    isAvailable: true, //if we want dynamic rendering the pass the correct moduleId and make isAvailable = false
    moduleId: "contacts",
    parentModuleId: "contacts",
    value: "communication-stats",
  },
  {
    label: "Activities",
    url: "/contacts/view/:contact_id/activities/notes",
    isDisabled: false,
    isAvailable: true, //if we want dynamic rendering the pass the correct moduleId and make isAvailable = false
    moduleId: "contacts",
    parentModuleId: "contacts",
    value: "notes",
  },
  {
    label: "Files",
    url: "/contacts/view/:contact_id/files",
    isDisabled: false,
    isAvailable: true, //if we want dynamic rendering the pass the correct moduleId and make isAvailable = false
    moduleId: "contacts",
    parentModuleId: "contacts",
    value: "files",
  },
];
