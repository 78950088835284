import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import { getIn, useFormikContext } from "formik";
import React, { Fragment, useEffect } from "react";
import styled from "styled-components";
import { Caption } from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";

interface Props {
  label?: string;
  name: string;
  tooltip?: any;
  divStyle?: any;
  subTitle?: string;
  sendValuesToParent?: (values: any) => void | undefined;
  children?: React.ReactNode;
  optionalText?: string;
  requiredSign?: boolean;
  labelStyle?: React.CSSProperties;
  count?: boolean;
  maxCount?: number;
  disableNewLineOnEnter?: boolean;
  [otherProps: string]: any;
}

interface StyledProps {
  isError?: any;
}

const AppFormTextArea: React.FC<Props> = ({
  label,
  name,
  tooltip,
  divStyle,
  subTitle,
  optionalText,
  sendValuesToParent,
  requiredSign,
  labelStyle,
  children,
  count,
  maxCount = 0,
  disableNewLineOnEnter,
  ...otherProps
}) => {
  const { setFieldTouched, errors, touched, values, setFieldValue } =
    useFormikContext<any>();

  useEffect(() => {
    if (sendValuesToParent) {
      sendValuesToParent(values);
    }
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (disableNewLineOnEnter && e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Div style={divStyle}>
      <div className="textLabelOptional">
        <Label style={labelStyle}>
          {requiredSign ? (
            <Fragment>
              {label}
              <sup className="starSuperScript"> *</sup>
            </Fragment>
          ) : (
            label
          )}
          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </Label>
        {optionalText && (
          <span style={{ color: "rgba(126, 131, 146, 1)", marginLeft: "5px" }}>
            {optionalText}
          </span>
        )}
      </div>
      {subTitle && <Caption>{subTitle}</Caption>}

      <Input
        {...otherProps}
        onKeyPress={handleKeyPress}
        onBlur={() => setFieldTouched(name)}
        onChange={(e: any) =>
          count && maxCount <= getIn(values, name).length
            ? {}
            : setFieldValue(name, e.target.value)
        }
        value={getIn(values, name) || ""}
        style={{
          border:
            getIn(errors, name) && getIn(touched, name)
              ? `2px solid ${theme.palette.default.error}`
              : `2px solid transparent`,
        }}
      />
      {count ? (
        <div style={{ textAlign: "right" }}>
          {getIn(values, name).length} / {maxCount}
        </div>
      ) : (
        ""
      )}
      <AppErrorMessage
        error={getIn(errors, name)}
        visible={getIn(touched, name)}
      />
    </Div>
  );
};

export default AppFormTextArea;

const Div = styled.div<StyledProps>`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 1rem;
  }
  .textLabelOptional {
    display: flex;
    gap: 0.5rem;
  }
`;
const Label = styled.label`
  && {
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    letter-spacing: 0.5px;
    color: ${theme.palette.default.darkGrey};
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 6px;
    svg {
      color: ${theme.palette.default.darkGrey};
      font-size: 15px;
      cursor: pointer;
    }
    sup {
      color: ${theme.palette.default.error};
    }
    .starSuperScript {
      top: 0;
      font-size: 16px;
    }
  }
`;
const Input = styled.textarea<StyledProps>`
  && {
    width: 100%;
    resize: none;
    background-color: #f5f6f8;
    max-height: 200px;
    border-radius: 4px;
    outline: none !important;
    padding: 0.5rem 2rem 0.5rem 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    position: relative;
    border: 2px solid transparent;

    &::placeholder {
      color: ${theme.palette.default.darkGrey};
    }

    &:active {
      border: 2px solid ${theme.palette.default.border};
      color: ${theme.palette.default.text};
    }
    &:focus {
      border: 2px solid ${theme.palette.default.border} !important;
      color: ${theme.palette.default.text};
    }
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }
`;
