import styled from "styled-components";
import { theme } from "../../../../../Customization/Theme";

export const StyledQueryBuilderWrap = styled.div`
  padding: 10px;
  overflow-y: auto;
  .queryBuilder {
    border: 1px solid ${theme.palette.default.border};
    padding: ${theme.spacing(2)};
    border-radius: ${theme.shape.borderRadius}px;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    background-color: ${theme.palette.default.lightGrey};
  }

  .conditionGroup {
    border: 1px solid ${theme.palette.default.border};
    border-radius: ${theme.shape.borderRadius}px;
    padding: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(2)};
    background-color: ${theme.palette.default.white};
    width: 100%;
  }

  .conditionHeader {
    font-weight: ${theme.typography.fontWeightBold};
    font-size: ${theme.typography.fontSize}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.spacing(1.5)};
  }

  .deleteGroupIcon {
    cursor: pointer;
  }

  .rule {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: ${theme.spacing(1)};
    gap: ${theme.spacing(1)};
  }

  .combinatorToggle {
    display: flex;
    gap: ${theme.spacing(1)};
    margin-bottom: ${theme.spacing(1.5)};
  }

  .combinatorButton {
    padding: ${theme.spacing(0.5)} ${theme.spacing(1.5)};
    border: none;
    background-color: ${theme.palette.default.grey};
    color: ${theme.palette.default.darkGrey};
    font-weight: ${theme.typography.fontWeightBold};
    cursor: pointer;
    border-radius: ${theme.shape.borderRadius / 2}px;
  }

  .combinatorButton.active {
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.default.white};
  }

  .fieldSelect,
  .operatorSelect,
  .valueInput,
  .addButton {
    padding: ${theme.spacing(0.75)};
  }

  .addButton {
    background-color: transparent;
    color: ${theme.palette.primary.main};
    border: none;
    cursor: pointer;
  }

  .groupIcon {
    cursor: pointer;
    color: ${theme.palette.default.darkGrey};
  }
`;
