import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Tooltip } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Field, getIn, useFormikContext } from "formik";
import styled from "styled-components";
import { Caption, Label } from "../../Customization/CommonElements";
import { theme } from "../../Customization/Theme";
import AppErrorMessage from "./AppErrorMessage";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

interface Props {
  label?: string;
  subTitle?: string;
  name: string;
  tooltip?: any;
  divStyle?: any;
  children?: React.ReactNode;
  disablePast?: boolean;
  [otherProps: string]: any;
  broadcastData?: any;
  onChangeCallbackFn?: (value: any) => void;
}

const AppFormDatePicker: React.FC<Props> = ({
  label,
  name,
  tooltip,
  divStyle,
  subTitle,
  children,
  disablePast,
  broadcastData,
  onChangeCallbackFn,
  ...otherProps
}) => {
  const { setFieldTouched, errors, touched, setFieldValue, values } =
    useFormikContext<any>();
  const { broadcast_id }: any = useParams();
  useEffect(() => {
    var parent = document.getElementsByClassName("input-date-picker");
    parent[0]
      .getElementsByClassName("MuiInputBase-input")[0]
      .setAttribute("disabled", "");
  }, []);

  useEffect(() => {
    // onChangeCallbackFn && onChangeCallbackFn(values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Div style={divStyle}>
        <Label>
          {label}

          {tooltip && (
            <Tooltip title={tooltip} placement="top">
              <HelpOutlineIcon />
            </Tooltip>
          )}
        </Label>
        {subTitle && <Caption>{subTitle}</Caption>}
        <Input>
          {({ field }: any) => (
            <DatePicker
              {...field}
              closeOnSelect
              className="input-date-picker"
              value={
                broadcast_id &&
                new Date(
                  broadcastData?.sent_on
                    ? broadcastData?.sent_on
                    : getIn(values, name)
                )
              }
              defaultValue={new Date()}
              onClose={() => setFieldTouched(name, true)}
              onChange={(newValue) => {
                setFieldValue(name, newValue);
                setFieldTouched(name, true);
                onChangeCallbackFn && onChangeCallbackFn(newValue);
              }}
              disablePast={disablePast}
              //   disableFuture={disableFuture}
              {...otherProps}
            />
          )}
        </Input>

        <AppErrorMessage
          error={getIn(errors, name)}
          visible={getIn(touched, name)}
        />
      </Div>
    </LocalizationProvider>
  );
};
export default AppFormDatePicker;

const Div = styled.div`
  && {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 4px;
    position: relative;
    margin-bottom: 0.5rem;
    .MuiTextField-root {
      width: 100%;
      border-radius: 4px;
      background-color: #f5f6f8;
      outline: none !important;
      padding: 0.4rem 0.5rem 0.4rem 1rem;
      font-family: ${theme.typography.fontFamily};
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      position: relative;
      text-overflow: ellipsis;
      border: 2px solid transparent;
    }
  }
`;

const Input = styled(Field)`
  && {
    width: 100%;

    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
    border: 2px solid ${theme.palette.default.border};
  }
  .input-date-picker {
    .MuiInputBase-input {
      padding: 0;
    }
  }
  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
  }
  &:disabled {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.darkGrey};
  }
`;
