export const InitialSelectValues = [
  "id",
  "first_name",
  "last_name",
  // "Actions",
  "email",
  "mobile_phone_number",
  // "Street Address",
  // "Country Region",
  // "State Region",
  // "Time Zone",
  // "Job Title",
  // "Industry",
  // "Postal Code",
  // "Company Name",
  // "Annual Revenue",
  // "Fax Number",
  // "Number of Employees",
  // "Father Name",
  // "Mother Name",
  // "Mobile Phone Number",
  // "WhatsApp Phone Number",
  // "Preferred language",
  // "Lifecycle Stage",
  // "Lifecycle Stage Status",
];
export const IdCoulumns = {
  label: "id",
  id: "id",
  icon: null,
  size: 50,
};
export const FirstNameColumn = {
  label: "First Name",
  id: "first_name",
  size: 500,
  toolTip: true,
  edit: true,
  icon: [
    {
      name: "LocalPhoneOutlined",
      action: () => {
        alert("hello");
      },
    },
    {
      name: "AlternateEmail",
      action: () => {
        alert("hello");
      },
    },
    {
      name: "ChatBubbleOutline",
      action: () => {
        alert("hello");
      },
    },
    {
      name: "ForumOutlined",
      action: () => {
        alert("hello");
      },
    },
  ],
};
