import http from "../../../../NetworkServices/http";

let consoleBaseUrl = process.env.REACT_APP_CH_CONSOLE_API_BASE_URL;
consoleBaseUrl = consoleBaseUrl + "api/v1";

export class ChatNetworkService {
  public static getChannelsListings(data: any) {
    const { limit, offset } = data;
    const url = `${consoleBaseUrl}${Endpoints.CHANNELS_LISTINGS}`;
    const headers = data.HEADERS;
    const params = { limit, offset };
    return http.get(url, { headers, params });
  }
  public static getLabelsListings(data: any) {
    const { limit, offset } = data;
    const url = `${consoleBaseUrl}${Endpoints.LABELS_LISTINGS}`;
    const headers = data.HEADERS;
    const params = { limit, offset };
    return http.get(url, { headers, params });
  }
  public static getAgentsListings(data: any) {
    const { limit, offset } = data;
    const url = `${consoleBaseUrl}${Endpoints.AGENTS_LISTINGS}`;
    const headers = data.HEADERS;
    const params = { limit, offset };
    return http.get(url, { headers, params });
  }

  public static getSessionsListings(payload: any) {
    const { limit, offset, data } = payload;
    const url = `${consoleBaseUrl}${Endpoints.SESSIONS_LISTINGS}`;
    const params = { limit, offset };
    return http.post(url, data, { params });
  }

  public static changeChatStatus(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.CHANGE_STATUS}`;
    return http.post(url, data);
  }
  public static changeChatStatusBulk(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.CHANGE_STATUS_BULK}`;
    return http.post(url, data);
  }

  public static changeChatLabel(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.ASSIGN_LABEL}`;
    return http.post(url, data);
  }
  public static changeChatLabelBulk(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.ASSIGN_LABEL_BULK}`;
    return http.post(url, data);
  }
  public static deleteChatLabel(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.ASSIGN_LABEL}`;
    return http.delete(url, { data: data });
  }
  public static reassignAgent(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.AGENT_REASSIGN}`;
    return http.post(url, data);
  }
  public static getChatContactDetails(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.CHAT_CONTACT_DETAILS}`;
    return http.post(url, data);
  }
  public static getChatContactPreviousConversations(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.CHAT_CONTACT_SESSIONS}`;
    return http.post(url, data);
  }
  public static sendWhatsappTemplate(data: any) {
    const url = `${consoleBaseUrl}${Endpoints.WHSTAPP_SEND_TEMPLATE}`;
    return http.post(url, data);
  }
  public static getChatCannedResponses(data: any) {
    let { limit, offset, search } = data;
    const headers = data.HEADERS;
    const params = { limit, offset };
    let searchQuery = '';
    if (search?.length > 0) {
      searchQuery =`?query=${search}` ?? '';
    }
    const url = `${consoleBaseUrl}${Endpoints.CHAT_CANNED_RESPONSES}${searchQuery}`;
    return http.get(url, { headers, params });
  }
}

//*whatsapp *//
class Endpoints {
  static CHANNELS_LISTINGS = "/workspace/inbox-list";
  static LABELS_LISTINGS = "/workspace/chat-labels";
  static AGENTS_LISTINGS = "/workspace/agent-list";
  static SESSIONS_LISTINGS = "/workspace/get-history";
  static CHANGE_STATUS = "/workspace/conversation-status";
  static CHANGE_STATUS_BULK = "/workspace/bulk-conversation-status";
  static ASSIGN_LABEL = "/workspace/conversation/assign-label";
  static ASSIGN_LABEL_BULK = "/workspace/conversation/bulk-assign-label";
  static AGENT_REASSIGN = "/workspace/conversation/agent-reassign";

  static CHAT_CONTACT_DETAILS = "/workspace/contact/contact-details";
  static CHAT_CONTACT_SESSIONS = "/workspace/contact/previous-conversation";
  static CHAT_CANNED_RESPONSES = "/console/search/canned-responses";
  static WHSTAPP_SEND_TEMPLATE = "/workspace/send/whatsapp/template"
}
