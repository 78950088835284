// vendors
import { useSelector } from "react-redux";
import isEqual from "lodash.isequal";
import { v4 as uuid } from "uuid";

// slices
import {
  getModifiedNodeData,
  getActiveNode,
  useNodes,
  getBotId,
  getQuickActions,
  getButtonsList,
  getLanguage,
  getDateConfiguration,
  getTimeConfiguration,
  getCarouselData,
  getInputValues,
  getConditionalFlowQuery,
} from "../Slices/main.slice";
import store, { useAppDispatch } from "../../../Store";
import {
  updateNode as updateNodeData,
  createNode,
  deleteNode,
} from "../Slices/main.service";

// utils
import {
  getText,
  getPlainTextData,
  getInitialEditiorText,
  getMediaDetails,
  getResponseWithoutText,
  getCarouselSlides,
  getInteractiveMessageData,
  getValueFromObject,
} from "../Utils";
import { getInputTypes, reset } from "./helper";
import { useNotification } from "../../../Hooks/useNotification/useNotification";

const useActions = () => {
  const modifiedNodeData = useSelector(getModifiedNodeData);
  const activeNode = useSelector(getActiveNode);
  const nodes = useSelector(useNodes);
  const botId = useSelector(getBotId);
  const quickActions = useSelector(getQuickActions);
  const buttonsList = useSelector(getButtonsList);
  const language = useSelector(getLanguage);
  const dateConfiguration = useSelector(getDateConfiguration);
  const timeConfiguration = useSelector(getTimeConfiguration);
  const carouselData = useSelector(getCarouselData);
  const inputValues = useSelector(getInputValues);
  const dispatch = useAppDispatch();
  const text = getPlainTextData(
    getInitialEditiorText(getText(modifiedNodeData))
  );
  const getUpdateConditionalQuery=()=>{
    let currentState = store.getState();
    const isQueryUpdate =
      currentState.flowBuilder.main.updatedconditionalFlowQuery;
    const conditionalFlowQuery =
      currentState.flowBuilder.main.conditionalFlowQuery;
    const conditional_flow = isQueryUpdate ? conditionalFlowQuery : undefined;
    return conditional_flow
  }
  const { showAlert } = useNotification();
  const getActiveNodeData = () => {
    const activeNodeData = nodes.filter(
      (node: any) => node.nodeId === activeNode
    );
    return activeNodeData[0];
  };
  const activeNodeData = getActiveNodeData();
  const commonFields = activeNode !== null && {
    nodeId: activeNode,
    displayOrder: activeNodeData.displayOrder,
    stage: activeNodeData.stage,
    subType: activeNodeData.subType,
    qid: activeNodeData.qid,
    isUnsaved: false,
  };

  const checkCarouselValidity = () => {
    return (
      carouselData?.value?.filter((slide: any) => slide.image === "").length ===
      0
    );
  };

  const updateCarousel = (hasQuickActionChanged: boolean) => {
    const carouselCheck =
      !isEqual(
        carouselData?.value,
        getCarouselSlides(activeNodeData.response, language)
      ) && checkCarouselValidity();
    if (hasQuickActionChanged || carouselCheck) {
      const data = {
        response: [carouselData],
        ...commonFields,
        quickActions: quickActions,
      };
      dispatch(updateNodeData({ botId, data })).then((res: any) => {
        if (res.payload && res.payload?.status === 200) {
          showAlert("Node Updated Successfully!", "success");
        }
      });
    } else {
      if (checkCarouselValidity()) reset(dispatch);
    }
  };

  const prepareMediaResponse = (mediaDetails: any) => {
    let mediaObj = { type: "", value: "" };
    if (isMediaDetailsExists(mediaDetails)) {
      for (let key in mediaDetails) {
        mediaObj.type = key;
        mediaObj.value = mediaDetails[key];
      }
      return mediaObj;
    }
  };

  const isMediaDetailsExists = (mediaDetails: any) => {
    return mediaDetails && Object.keys(mediaDetails).length !== 0;
  };

  const updateButtons = (hasQuickActionChanged: boolean) => {
    const buttonCheck = !isEqual(
      buttonsList,
      getResponseWithoutText(activeNodeData, language)
    );
    const mediaDetails = getMediaDetails(modifiedNodeData);
    const mediaCheck = !isEqual(
      mediaDetails,
      getMediaDetails(activeNodeData.response)
    );

    if (
      getText(activeNodeData.response) !== getText(modifiedNodeData) ||
      mediaCheck ||
      hasQuickActionChanged ||
      buttonCheck
    ) {
      const mediaResponse = prepareMediaResponse(mediaDetails);
      const conditional_flow = getUpdateConditionalQuery()
      const data = {
        response:
          mediaResponse && mediaResponse !== null
            ? [...[mediaResponse], ...modifiedNodeData, ...buttonsList]
            : [...modifiedNodeData, ...buttonsList],
        ...commonFields,
        quickActions: quickActions,
        conditional_flow,
      };
      dispatch(updateNodeData({ botId, data })).then((res: any) => {
        if (res.payload && res.payload?.status === 200) {
          showAlert("Node Updated Successfully!", "success");
        }
      });
    } else {
      reset(dispatch);
    }
  };

  const updateDate = (hasQuickActionChanged: boolean) => {
    const previousDateConfig = {
      dateFormat: activeNodeData?.dateFormat,
      from: activeNodeData?.from,
      to: activeNodeData?.to,
      dateRange: activeNodeData?.dateRange,
      excluded: activeNodeData.excluded,
    };
    const dateCheck = !isEqual(dateConfiguration, previousDateConfig);

    if (
      getText(activeNodeData.response) !== getText(modifiedNodeData) ||
      hasQuickActionChanged ||
      dateCheck
    ) {
      const conditional_flow = getUpdateConditionalQuery()
      const data = {
        response: modifiedNodeData,
        ...commonFields,
        quickActions: quickActions,
        ...dateConfiguration,
        ...getInputTypes(activeNodeData.subType),
        conditional_flow,
      };
      dispatch(updateNodeData({ botId, data })).then((res: any) => {
        if (res.payload && res.payload?.status === 200) {
          showAlert("Node Updated Successfully!", "success");
        }
      });
    } else {
      reset(dispatch);
    }
  };

  const updateTime = (hasQuickActionChanged: boolean) => {
    const timeCheck = !isEqual(timeConfiguration, activeNodeData.time);
    if (
      getText(activeNodeData.response) !== getText(modifiedNodeData) ||
      hasQuickActionChanged ||
      timeCheck
    ) {
      const conditional_flow = getUpdateConditionalQuery()
      const data = {
        response: modifiedNodeData,
        ...commonFields,
        quickActions: quickActions,
        ...getInputTypes(activeNodeData.subType),
        ...{ time: timeConfiguration },
        conditional_flow,
      };
      dispatch(updateNodeData({ botId, data })).then((res: any) => {
        if (res.payload && res.payload?.status === 200) {
          showAlert("Node Updated Successfully!", "success");
        }
      });
    } else {
      reset(dispatch);
    }
  };

  const updateInputTypes = (hasQuickActionChanged: boolean) => {
    if (
      getText(activeNodeData.response) !== getText(modifiedNodeData) ||
      hasQuickActionChanged ||
      (activeNodeData.subType === "img-file" &&
        inputValues?.value &&
        !isEqual(activeNodeData.allowedFileTypes, inputValues.value)) ||
      (activeNodeData.subType === "custom" &&
        inputValues?.value &&
        !isEqual(activeNodeData.customRegex, inputValues.value))
    ) {
      const conditional_flow = getUpdateConditionalQuery()
      const data = {
        response: modifiedNodeData,
        ...commonFields,
        quickActions: quickActions,
        ...getInputTypes(activeNodeData.subType, inputValues),
        conditional_flow,
      };
      dispatch(updateNodeData({ botId, data })).then((res: any) => {
        if (res.payload && res.payload?.status === 200) {
          showAlert("Node Updated Successfully!", "success");
        }
      });
    } else {
      reset(dispatch);
    }
  };

  const updateText = (hasQuickActionChanged: boolean) => {
    const mediaDetails = getMediaDetails(modifiedNodeData);
    if (
      getText(activeNodeData.response) !== getText(modifiedNodeData) ||
      !isEqual(mediaDetails, getMediaDetails(activeNodeData.response)) ||
      hasQuickActionChanged 
    ) {
      const mediaResponse = prepareMediaResponse(mediaDetails);
      const conditional_flow = getUpdateConditionalQuery()
      const data = {
        response:
          mediaResponse && mediaResponse !== null
            ? [...[mediaResponse], ...modifiedNodeData]
            : [...modifiedNodeData],
        ...commonFields,
        quickActions,
        conditional_flow,
      };
      console.log("==> data",data)
      dispatch(updateNodeData({ botId, data })).then((res: any) => {
        if (res.payload && res.payload?.status === 200) {
          showAlert("Node Updated Successfully!", "success");
        }
      });
    } else {
      reset(dispatch);
    }
  };
  // Function to check validation on button list of interactive message node
  const areButtonsValid = (array: any) => {
    for (const item of array) {
      const buttons = item?.interactive?.action?.buttons;
      if (!buttons || buttons.length < 1) {
        return false;
      }
      for (const button of buttons) {
        if (!button.type) {
          return false;
        }
      }
    }
    return true;
  };
  // Function to check validation on section & button list of interactive message list node
  const areSectionsValid = (array: any) => {
    for (const item of array) {
      const sections = item?.interactive?.action?.sections;
      if (!sections) {
        return false;
      }
      for (const section of sections) {
        const rows = section?.rows;
        if (!rows || rows.length < 1 || section.title.trim() === "") {
          return false;
        }
        for (const row of rows) {
          if (!row.type) {
            return false;
          }
        }
      }
    }
    return true;
  };

  const updateInteractiveMessageNode = () => {
    const dataCheck = isEqual(
      modifiedNodeData,
      getInteractiveMessageData(activeNodeData, language)
    );

    const interactiveNode = modifiedNodeData[0]?.interactive;

    const bodyText = interactiveNode?.body;
    const headerText = interactiveNode?.header;

    const bodyTextCheck = bodyText === "" || bodyText === "\n";
    const headerTextCheck =
      (typeof headerText === "string" && headerText.trim() === "") ||
      (typeof headerText?.text === "string" && headerText.text.trim() === "");
    const isButtonValid = areButtonsValid(modifiedNodeData);
    if (!dataCheck) {
      // Data validation check for message node
      if (bodyTextCheck || !isButtonValid || headerTextCheck) return;
      const conditional_flow = getUpdateConditionalQuery()
      const data = {
        response: [...modifiedNodeData],
        ...commonFields,
        conditional_flow,
      };
      dispatch(updateNodeData({ botId, data })).then((res: any) => {
        if (res.payload && res.payload?.status === 200) {
          showAlert("Node Updated Successfully!", "success");
        }
      });
    } else {
      reset(dispatch);
    }
  };

  const updateInteractiveWhasappFlow = () => {
    const dataCheck = isEqual(
      modifiedNodeData,
      getInteractiveMessageData(activeNodeData, language)
    );
    const bodyTextCheck = modifiedNodeData?.[0]?.interactive.body === "";
    const isButtonValid =
      modifiedNodeData[0].interactive?.action?.buttons &&
      modifiedNodeData[0].interactive?.action?.buttons.length > 0;
    if (!modifiedNodeData[0]?.interactive?.action?.buttons[0]?.value?.flow_id) {
      showAlert("Please Select Flow", "error");
      return;
    }
    if (!dataCheck) {
      if (bodyTextCheck || !isButtonValid) return;
      const conditional_flow = getUpdateConditionalQuery()
      const data = {
        response: [...modifiedNodeData],
        ...commonFields,
        conditional_flow,
      };
      dispatch(updateNodeData({ botId, data })).then((res: any) => {
        if (res.payload && res.payload?.status === 200) {
          showAlert("Node Updated Successfully!", "success");
        }
      });
    } else {
      reset(dispatch);
    }
  };

  const updateOTP = (hasQuickActionChanged: boolean) => {
    if (
      getValueFromObject(activeNodeData.response, "id") !==
      getValueFromObject(modifiedNodeData, "id")
    ) {
      const conditional_flow = getUpdateConditionalQuery()
      const data = {
        response: [...modifiedNodeData],
        ...commonFields,
        quickActions,
        conditional_flow,
      };
      dispatch(updateNodeData({ botId, data })).then((res: any) => {
        if (res.payload && res.payload?.status === 200) {
          showAlert("Node Updated Successfully!", "success");
        }
      });
    } else {
      reset(dispatch);
    }
  };

  const updateInteractiveMessageListNode = () => {
    const dataCheck = isEqual(
      modifiedNodeData,
      getInteractiveMessageData(activeNodeData, language)
    );
    const getTotalItem = (sectionsList: any) => {
      let totalItems = 0;
      sectionsList.map((section: any) => {
        totalItems += section.rows.length;
      });
      return totalItems;
    };
    let buttonsCount = 0;
    if (modifiedNodeData[0].interactive?.action?.sections) {
      buttonsCount = getTotalItem(
        modifiedNodeData[0].interactive?.action?.sections
      );
    }
    const interactiveNode = modifiedNodeData[0]?.interactive;

    const bodyText = interactiveNode?.body;
    const headerText = interactiveNode?.header;

    const bodyTextCheck = bodyText === "" || bodyText === "\n";
    const headerTextCheck =
      (typeof headerText === "string" && headerText.trim() === "") ||
      (typeof headerText?.text === "string" && headerText.text.trim() === "");

    const buttonTextCheck =
      modifiedNodeData[0]?.interactive?.action?.button?.trim() === "";
    const isValidSection = areSectionsValid(modifiedNodeData);
    if (!dataCheck) {
      const conditional_flow = getUpdateConditionalQuery()
      const data = {
        response: [...modifiedNodeData],
        ...commonFields,
        conditional_flow,
      };
      // Data validation check for interactive message list node
      if (
        !isValidSection ||
        bodyTextCheck ||
        headerTextCheck ||
        buttonTextCheck ||
        buttonsCount === 0
      )
        return;

      dispatch(updateNodeData({ botId, data })).then((res: any) => {
        if (res.payload && res.payload?.status === 200) {
          showAlert("Node Updated Successfully!", "success");
        }
      });
    } else {
      reset(dispatch);
    }
  };

  const updateNode = () => {
    const mediaDetails = getMediaDetails(modifiedNodeData);
    const newState = store.getState();
    const conditionalDrawer = newState.flowBuilder.main.rightDrawer;
    const conditionalDrawerStatus =
      conditionalDrawer.componentType == "conditional-flow" &&
      conditionalDrawer.isOpen;

    if (activeNode == null && !activeNodeData) return;

    if (conditionalDrawerStatus) {
      return;
    }

    const isMediaAdded = mediaDetails && Object.keys(mediaDetails).length;
    if (
      activeNodeData?.subType !== "carousel" &&
      activeNodeData?.subType !== "interactive-message" &&
      activeNodeData?.subType !== "interactive-message-list" &&
      text === "" &&
      isMediaAdded === 0
    )
      return;
    const isQuickActionChanged =
      quickActions.length > 0
        ? !isEqual(quickActions, activeNodeData?.quickActions)
        : false || !conditionalDrawerStatus;
    switch (activeNodeData?.subType) {
      case "carousel":
        updateCarousel(isQuickActionChanged);
        break;
      case "date":
        updateDate(isQuickActionChanged);
        break;
      case "time":
        updateTime(isQuickActionChanged);
        break;
      case "button":
        updateButtons(isQuickActionChanged);
        break;
      case "keyboard_button":
        updateButtons(isQuickActionChanged);
        break;
      case "quick_replies":
        updateButtons(isQuickActionChanged);
        break;
      case "textfield":
        updateInputTypes(isQuickActionChanged);
        break;
      case "custom":
        updateInputTypes(isQuickActionChanged);
        break;
      case "number":
        updateInputTypes(isQuickActionChanged);
        break;
      case "email":
        updateInputTypes(isQuickActionChanged);
        break;
      case "phone-number":
        updateInputTypes(isQuickActionChanged);
        break;
      case "name":
        updateInputTypes(isQuickActionChanged);
        break;
      case "img-file":
        updateButtons(isQuickActionChanged);
        break;
      case "otp":
        updateOTP(isQuickActionChanged);
        break;
      case "alphanumeric":
        updateInputTypes(isQuickActionChanged);
        break;
      case "interactive-message":
        updateInteractiveMessageNode();
        break;
      case "interactive-message-list":
        updateInteractiveMessageListNode();
        break;
      case "flow-list":
        updateInteractiveWhasappFlow();
        break;
      default:
        updateText(isQuickActionChanged);
        break;
    }
  };

  const addChildren = (...args: any[]) => {
    const [status, nodeId, qid, flowOrder] = args;
    const id = uuid();
    const activeNodeData = nodes.find((node: any) => node.nodeId === nodeId);

    if (status) {
      const node = {
        id,
        qid: qid,
        subType: "otp",
        displayOrder: activeNodeData.displayOrder + 1,
        stage: `${flowOrder}.${activeNodeData.displayOrder + 1}`,
        isInBetween: activeNodeData.displayOrder + 1 <= nodes.length,
        isUnsaved: false,
        parentId: nodeId,
        parentNode: "phone-number",
        response: [{ type: "text", value: "Please enter OTP." }],
      };
      const conditional_flow = getUpdateConditionalQuery()
      const data = {
        nodeId: activeNodeData.nodeId,
        displayOrder: activeNodeData.displayOrder,
        stage: activeNodeData.stage,
        subType: activeNodeData.subType,
        qid: activeNodeData.qid,
        isUnsaved: false,
        childrens: activeNodeData?.childrens ? activeNodeData?.childrens : [],
        hasOTPVarification: status,
        conditional_flow,
      };

      dispatch(createNode({ botId, node })).then((res: any) => {
        if (res.payload && res.payload?.status === 200) {
          data.childrens = [...data.childrens, ...[id]];
          dispatch(updateNodeData({ botId, data })).then((res: any) => {
            if (res.payload && res.payload?.status === 200) {
              showAlert("Node Updated Successfully!", "success");
            }
          });
        }
      });
    } else {
      const childNode = nodes.find((node: any) => node.parentId === nodeId);
      deleteChildrenNode(
        qid,
        childNode.nodeId,
        activeNodeData.displayOrder + 1 < nodes.length,
        nodeId,
        childNode.id
      );
    }
  };

  const deleteChildrenNode = (...args: any[]) => {
    const [flowId, nodeId, isInBetween, parentId, id] = args;
    const parentNode = nodes.find((node: any) => node.nodeId === parentId);
    const childrens = parentNode?.childrens
      ? parentNode?.childrens?.filter((childrenId: string) => {
          return childrenId != id;
        })
      : [];
    dispatch(
      deleteNode({
        botId,
        qid: flowId,
        nodeId,
        isInBetween,
        parentId,
        id,
        childrens,
        fetchFlowNeeded: false,
      })
    );
  };

  const updateActions = (actions: any, nodeId: string, qid: string) => {
    const conditional_flow = getUpdateConditionalQuery()
    const data = {
      qid,
      nodeId,
      actions,
      conditional_flow,
    };

    dispatch(updateNodeData({ botId, data })).then((res: any) => {
      if (res.payload && res.payload?.status === 200) {
        showAlert("Node Updated Successfully!", "success");
      }
    });
  };

  const saveProperty = (property: any, callback: () => void) => {
    const conditional_flow = getUpdateConditionalQuery()
    const data = {
      ...property,
      ...commonFields,
      variable: property.property.property.value,
      conditional_flow,
    };
    dispatch(updateNodeData({ botId, data })).then((res: any) => {
      if (res.payload && res.payload?.status === 200) {
        showAlert("Property added successfully!", "success");
        callback();
      }
    });
  };

  return {
    updateNode,
    addChildren,
    deleteChildrenNode,
    updateActions,
    saveProperty,
  };
};
export default useActions;
