import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import UIButton from "../Button";
import IconButton from "@mui/material/IconButton";
import Icon from "../Icon";
import { ToolbarStyled } from "../Drawer/drawer.styled";
import { Divider } from "@mui/material";
import {
  AddNewconditionalFlowQuery,
  getConditionalFlowQuery,
  getConditionalFlowStage,
  handleRightDrawer,
  saveConditionalFlowQuery,
  UpdateconditionalFlowQuery,
} from "../../Slices/main.slice";
import { useAppDispatch } from "../../../../Store";
import QueryBuilderComponent from "./Common/QueryBuilder";
import { useSelector } from "react-redux";
import useActions from "../../Core/useActions";
import { checkSelectedAndActionValues } from "./Common/utils";

const ConditionalNodeDrawer = () => {
  const dispatch = useAppDispatch();
  const query = useSelector(getConditionalFlowQuery);
  const activeFlowStage = useSelector(getConditionalFlowStage);

  const { updateNode } = useActions();
  const handleConditionDrawer = (event: any) => {
    event.stopPropagation();
    dispatch(handleRightDrawer({ componentType: "", isOpen: false }));
    dispatch(UpdateconditionalFlowQuery([]));
  };
  const addNewConditionStatus = query[query.length - 1]?.action?.negative;
  const handleSave = async () => {
    dispatch(handleRightDrawer({ componentType: "", isOpen: false }));
    dispatch(saveConditionalFlowQuery(true));
    updateNode();
  };

  const handleAddNew = () => {
    dispatch(
      AddNewconditionalFlowQuery({
        combinator: "AND",
        conditions: [{ field: "", operator: "equals", value: "" }],
        action: {
          positive: {
            nodeId: "",
            qid: "",
            stage: "",
          },
        },
      })
    );
  };
  return (
    <>
      <ToolbarStyled className="ignore-onClickOutside">
        <Stack
          sx={{ width: "100%" }}
          flexDirection="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="subtitle1" color="default.black">
            Condition-Dialog {activeFlowStage}
          </Typography>
          <UIButton
            disabled={addNewConditionStatus}
            onClick={handleAddNew}
            variant="outlined"
            size="medium"
          >
            New Condition
          </UIButton>
          <IconButton sx={{ ml: "16px", display: "none" }}>
            <Icon icon="settings" size={24} />
          </IconButton>
        </Stack>
      </ToolbarStyled>
      <Divider />
      <QueryBuilderComponent />
      <ToolbarStyled
        className="ignore-onClickOutside"
        sx={{ mt: "auto", borderTop: "1px solid #0000001f" }}
      >
        <Stack
          sx={{ width: "100%" }}
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          gap={2}
        >
          <UIButton
            variant="outlined"
            size="medium"
            onClick={handleConditionDrawer}
          >
            Cancel
          </UIButton>
          <UIButton
            variant="contained"
            size="medium"
            disabled={!checkSelectedAndActionValues(query)}
            onClick={handleSave}
          >
            Save
          </UIButton>
        </Stack>
      </ToolbarStyled>
    </>
  );
};

export default ConditionalNodeDrawer;
