import { StyledProps } from "@material-ui/core";
import { Box, IconButton, Paper } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import AppButton from "../../../../../AppComponents/AppButton";
import { AppMaterialIcons } from "../../../../../AppComponents/AppMaterialIcons";
import AppNoDataLoader from "../../../../../AppComponents/AppNoDataLoader";
import { withLoader } from "../../../../../AppComponents/Loader";
import { theme } from "../../../../../Customization/Theme";
import AppModel from "../../../../../Hooks/useModel/AppModel";
import useModal from "../../../../../Hooks/useModel/useModel";
import { useNotification } from "../../../../../Hooks/useNotification/useNotification";
import { useAppDispatch, useAppSelector } from "../../../../../Store";
import { ContactsActions } from "../../../../../Store/Slices/Contacts/contacts.action";
import { canAddNotes, getAllNotes, getTotalNoteCount } from "../../../../../Store/Slices/Contacts/contacts.selector";
import Danger from "../../../../../assets/images/danger-live.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/images/delete-icon.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/images/edit-icon.svg";
import { ReactComponent as NotesIcon } from "../../../../../assets/images/icons/notes.svg";
import { AppPagination } from "../../../../BotBuilder/BotKnowledge/BotKnowledgeStyles";
import { DeleteCannedResponse } from "../../../../Broadcast/WhatsApp/Templates/TemplatesListing";
import { LeadPageWrapper } from "../../../LeadsStyles";

const LeadNotes = (props: any): JSX.Element => {
  const params = useParams();
  const { showAlert } = useNotification()
  const dispatch = useAppDispatch()
  const notes: any = useAppSelector(getAllNotes);
  const updateNote: any = useAppSelector(canAddNotes)
  const notesCount: any = useAppSelector(getTotalNoteCount)
  const [localValues, setLocalValues] = useState<any>({
    itemsPerPage: 10,
    currentPage: 0,
  });
  const useModal1 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal1: isShowing, toggleModal1: toggle };
  };
  const useModal7 = () => {
    const { isShowing, toggle } = useModal();
    return { isShowingModal7: isShowing, toggleModal7: toggle };
  };
  const { isShowingModal1, toggleModal1 } = useModal1();
  const { isShowingModal7, toggleModal7 } = useModal7();
  const [noteContext, setNoteContext] = useState<any>();
  const [selectedContactId, setSelectedContactId] = useState<any>();
  const [noData, setNoData] = useState(false);
  useEffect(() => {
    if (notes.length === 0) {
      setNoData(true)
    }
  }, [notes])

  const getNotes = () => {
    let data = {
      contact_id: params.contact_id,
      limit: localValues.itemsPerPage,
      offset: localValues.currentPage
    }
    props.setLoading(true);
    dispatch(ContactsActions.getAllNotes(data)).finally(() => {
      // setLoading(false);
      props.setLoading(false);
    });
  }
  useEffect(() => {
    getNotes()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  // Handle Pagination Functions
  const handlePageChange = (event: any, value: any) => {
    setLocalValues({
      ...localValues,
      currentPage: value,
    });
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLocalValues({
      ...localValues,
      itemsPerPage: parseInt(event.target.value),
      currentPage: 0,
    });
  };
  useEffect(() => {
    if (localValues) {
      getNotes()
    }
  }, [localValues])  // eslint-disable-line react-hooks/exhaustive-deps
  const addNote = () => {
    if (selectedContactId) {
      let data = {
        contact_id: params.contact_id,
        context: noteContext,
        note_id: selectedContactId
      };
      // setLoader(true);
      dispatch(ContactsActions.updateNote(data))
        .then((response: any) => {
          showAlert(response?.payload?.data?.message, "success");
          toggleModal7()
          setSelectedContactId('')
          props.setLoading(true);
          dispatch(ContactsActions.getAllNotes({ contact_id: params.contact_id, })).finally(() => {
            props.setLoading(false)
          });
        }).catch((error: any) => {
        })
        .finally(() => { });
    } else {
      let data = {
        contact_id: params.contact_id,
        context: noteContext,
      };
      // setLoader(true);
      dispatch(ContactsActions.addNoteAsPerId(data))
        .then((response: any) => {
          showAlert(response?.payload?.data?.message, "success");
          toggleModal7()
          setSelectedContactId('')
          props.setLoading(true);
          dispatch(ContactsActions.getAllNotes({ contact_id: params.contact_id, })).finally(() => {
            props.setLoading(false)
          });
        }).catch((error: any) => {
        }).finally(() => { });
    }

  };
  const handleUpdateNote = (nodeId: any, context: any) => {
    setSelectedContactId(nodeId)
    setNoteContext(context)
    toggleModal7()
  }
  const deleteNote = () => {
    let data = {
      contact_id: params.contact_id,
      note_id: selectedContactId,
    }
    props.setLoading(true);
    dispatch(ContactsActions.deleteNote(data))
      .then((response: any) => {
        showAlert(response?.payload?.data?.message, "success");
        setSelectedContactId('')
        toggleModal1()
        props.setLoading(true);
        dispatch(ContactsActions.getAllNotes({ contact_id: params.contact_id, })).finally(() => {
          props.setLoading(false)
        });
      }).catch((error: any) => {
      }).finally(() => { });
  }
  return (
    <ContactNotesWrapper>
      <div className="leadsPageWrapper">
        {/* <LeadPageSideBar />  */}
        <div className="userprofile-main-div">
          <LeadPageWrapper>
            <div className="contact-notes">
              <div className="notes">
                {updateNote?.can_update &&
                  <AppButton onClick={() => { toggleModal7(); setSelectedContactId(''); setNoteContext('') }} variant=" ">
                    <AppMaterialIcons iconName="add" />
                    Add Note
                  </AppButton>
                }
              </div>

              {notes.length !== 0 && notes.map((res: any) => {
                return (
                  <div className="notes-details">
                    <div className="notes-icon">
                      <NotesIcon />
                    </div>
                    <div className="note-avtar">
                      <p className="name">
                        Created By: <strong>{res.created_by_username}</strong>
                      </p>
                      <small className="updated-time">{moment(res.updated_at).format("DD MMM , YYYY hh:mm A ")}</small>
                    </div>
                    <div className="notes-text">
                      <p>
                        {res.context}
                      </p>
                      <div className="note-edit-delete">
                        <IconButton>
                          <EditIcon onClick={() => handleUpdateNote(res.id, res.context)} />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon onClick={() => { toggleModal1(); setSelectedContactId(res.id) }} />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                )
              })}
              {notes.length === 0 && noData ?
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    padding: "4rem 2rem 2rem 2rem",
                    boxShadow: "none important",
                    backgroundColor: "white",
                  }}
                >
                  <Paper
                    sx={{
                      width: "40%",
                      mb: 2,
                      boxShadow: "none !important",
                      backgroundColor: "transparent",
                    }}
                  >
                    <AppNoDataLoader />
                  </Paper>
                </Box>
                : null}

            </div>
          </LeadPageWrapper>
        </div>
      </div>
      {/* add note modal */}
      <AppModel
        isShowing={isShowingModal7}
        divStyle={{
          width: "550px",
          height:" 260px",
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <DeleteCannedResponse>
          <div className="text">
            <h4>Add Note</h4>
          </div>
          <div style={{ height: "100%", paddingLeft: 0, paddingRight: 0}} className="header">
            <Textarea
              value={noteContext || ''}
              onChange={(e: any) => {
                setNoteContext(e.target.value);
              }}
            />
          </div>
          <div className="footer">
            <AppButton variant="grey" onClick={() => { toggleModal7(); setSelectedContactId("") }} style={{}}>
              Cancel
            </AppButton>
            <AppButton onClick={() => addNote()} style={{}}>
              Update
            </AppButton>
          </div>
        </DeleteCannedResponse>
      </AppModel>

      <AppModel
        isShowing={isShowingModal1}
        onClose={() => toggleModal1()}
        divStyle={{
          width: "550px",
          gap: "0.5rem",
          overflowY: "hidden",
          padding: "1rem 1rem",
        }}
      >
        <DeleteCannedResponse>
          <div className="header">
            <div className="header-left">
              <div className="danger">
                <img src={Danger} alt="" />
              </div>
              <div className="text">
                <h4>Delete The Notes</h4>
                <p>Are you sure, you want to delete?</p>
              </div>
            </div>
            <div className="header-right">
              <span onClick={() => toggleModal1()}>
                <AppMaterialIcons iconName="close" />
              </span>
            </div>
          </div>
          <div className="footer">
            <AppButton variant="grey" onClick={() => toggleModal1()} style={{}}>
              Cancel
            </AppButton>
            <AppButton
              variant="danger-filled"
              onClick={() => deleteNote()}
              style={{}}
            >
              <AppMaterialIcons iconName="DeleteOutlineOutlined" />
              Delete
            </AppButton>
          </div>
        </DeleteCannedResponse>
      </AppModel>
      <AppPagination
        component="div"
        rowsPerPageOptions={[10, 25, 50]}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={localValues?.currentPage}
        count={notesCount}
        rowsPerPage={localValues?.itemsPerPage}
      />
    </ContactNotesWrapper>
  );
};

export default withLoader(LeadNotes);

const ContactNotesWrapper = styled.div`
  .contact-notes {
    padding: 16px;
  }
  .notes {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 0px 0px 8px;
    margin-bottom: 16px;
  }
  .notes button {
    min-height: 37px;
    height: 37px;
  }
  .notes h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #101010;
  }
  .notes-details {
    border: 1px solid ${theme.palette.default.border};
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 15px;
    gap: 15px;
    border-radius: 10px;
  }
  .notes-text {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 30px;
    align-items: center;
  }
  .notes-text p {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #7e8392;
  }
  .note-edit-delete {
    display: flex;
    gap: 21px;
    button {
      padding: 0px;
      width: 40px;
      height: 40px;
    }
  }
  .notes-icon {
    padding-right: 15px;
    border-right: 1px solid ${theme.palette.default.border};
    display: flex;
    align-items: center;
  }
  .note-avtar {
    gap: 8px;
    min-width: 220px;
  }
`;
const Textarea = styled.textarea<StyledProps>`
  && {
    width: -webkit-fill-available;
    height: inherit;
    border-radius: 4px;
    background-color: #f5f6f8;
    outline: none !important;
    padding: 4px 2rem 8px 14px;
    padding: 6px 1rem 6px 14px;
    font-family: ${theme.typography.fontFamily};
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    position: relative;
    text-overflow: ellipsis;
    /* overflow:hidden; */
    resize: none;

    /* ::-webkit-scrollbar {
   display: none;
   width: 1px;
 } */
  }

  &::placeholder {
    color: ${theme.palette.default.darkGrey};
  }

  &:active {
    border: 2px solid ${theme.palette.default.border};
    color: ${theme.palette.default.text};
  }
  &:focus {
    border: 2px solid ${theme.palette.default.border} !important;
    color: ${theme.palette.default.text};
    // height: 80px;
  }
`;